import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./articles.css";
/**
 *
 */
const ListeningTC = () => {
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	// Keep all left and right section refs in arrays
	const lSections = useRef([]);
	const rSections = useRef([]);

	// Methods to create refs when called upon DOM elements
	const pushLRef = (el) => lSections.current.push(el);
	const pushRRef = (el) => rSections.current.push(el);

	// Tracks video visibility. Set upon scroll position
	const [isVideo1Visible, setShowVideo1] = useState(true);
	const [isVideo2Visible, setShowVideo2] = useState(false);
	// const [isVideo3Visible, setShowVideo3] = useState(false);
	const [isVideo4Visible, setShowVideo4] = useState(false);
	const [isVideo5Visible, setShowVideo5] = useState(false);
	// const [isVideo6Visible, setShowVideo6] = useState(false);
	// const [isVideo7Visible, setShowVideo7] = useState(false);
	// const [isVideo8Visible, setShowVideo8] = useState(false);

	useEffect(() => {
		let tl = gsap.timeline();
		let mm = gsap.matchMedia();
		const sectionsLength = lSections.current.length;

		for (let i = 0; i < sectionsLength; i++) {
			mm.add("(min-width: 992px)", () => {
				let startVal = "clamp(top top)";
				let endVal = "clamp(bottom center)";
				if (i < 2) {
					startVal = "clamp(top 64px)";
					endVal = "clamp(bottom bottom-400px)";
				}

				tl.to(rSections.current[i], {
					scrollTrigger: {
						pin: lSections.current[i],
						trigger: rSections.current[i],
						start: startVal,
						end: endVal,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						markers: false,
						// Tracks scroll progression (progress is a value of 0-1)
						// i value represents which left section is currently in view
						onUpdate: ({ progress }) => {
							// console.log("progress: ", progress.toFixed(2));
							if (i === 0) {
								/*
								if (progress >= 0.7) {
									//show video 6
									// console.log("show video 6");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									// setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(false);
									// setShowVideo6(true);
								} else */ if (progress >= 0.55) {
									//show video 5
									// console.log("show video 5");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									// setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(true);
									// setShowVideo6(false);
								} else if (progress >= 0.4) {
									//show video 4
									// console.log("show video 4");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									// setShowVideo3(false);
									setShowVideo4(true);
									setShowVideo5(false);
									// setShowVideo6(false);
								} else if (progress >= 0.24) {
									//show video 3
									// console.log("show video 3");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(true);
									// setShowVideo3(true);
									setShowVideo4(false);
									setShowVideo5(false);
									// setShowVideo6(false);
								} else {
									//show video 2
									// console.log("show video 2");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(true);
									setShowVideo2(false);
									// setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(false);
									// setShowVideo6(false);
								}
							}
						},
					},
				});
			});
		}

		ScrollTrigger.sort();
	}, [lSections]);

	return (
		<div className="main-wrapper">
			<MainMenu />

			{/* BEGIN YOUR CUSTOM MARKUP HERE */}

			<div className="hero light-mode ">
				<div className="left listening curtain">
					<h1>
						<div className="eyebrow">Experiment 02:</div>From listening to
						conversing: Consumers, Reddit, and JTBD
					</h1>
					<div className="info">
						<div className="breadcrumb">Consumer Research + ai</div>
						<div className="tagging">
							<ul>
								<li>#Consumerresearch</li>
								<li>#Insights</li>
								<li>#Brand</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="right">
					<p className="title">
						Play<span>Another Lippincott AI Exploration</span>
					</p>
					<section>
						<p className="initial-cap">
							Look who’s talking! It’s our friend AI!
						</p>
						<p>
							All this exploration has us thinking. We know AI can be useful as
							a listening tool. What about a <em>conversation tool?</em>
						</p>
						<p>
							Can AI not only enable us to have more conversations, but to have
							them faster and at scale?
						</p>
						<p>Let’s chat.</p>
					</section>
				</div>
			</div>
			<div className="scrollingsections">
				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<img
									id="image1"
									className={`${isVideo1Visible ? "" : "hide"}`}
									src="../images/listening/article3_accent1_comp.jpg"
									alt="Enormous Library"
								/>
								<video
									id="video2"
									controls
									data-height="405"
									className={`${isVideo2Visible ? "" : "hide"}`}
									poster="../images/naming/video1.png"
									src="../images/listening/Visceral 1A.mp4"
								></video>
								{/* <video
									id="video3"
									controls
									className={`${isVideo3Visible ? "" : "hide"}`}
									poster="../images/listening/Visceral 1B_Anon.jpg"
									src="../images/listening/Visceral 1B_Anon.mp4"
								></video> */}
								<video
									id="video4"
									controls
									data-height="335"
									className={`${isVideo4Visible ? "" : "hide"}`}
									poster="../images/naming/video2.png"
									src="../images/listening/Visceral 1B.mp4"
								></video>{" "}
								<video
									id="video5"
									controls
									data-height="405"
									className={`${isVideo5Visible ? "" : "hide"}`}
									poster="../images/naming/video3.png"
									src="../images/listening/Visceral 1C.mp4"
								></video>{" "}
								{/* <video
									id="video6"
									controls
									className={`${isVideo6Visible ? "" : "hide"}`}
									poster="../images/listening/Visceral 2A.jpg"
									src="../images/listening/Visceral 2A.mp4"
								></video>{" "}
								<video
									id="video7"
									controls
									className={`${isVideo7Visible ? "" : "hide"}`}
									poster="../images/listening/Visceral 2B.jpg"
									src="../images/listening/Visceral 2B.mp4"
								></video>{" "}
								<video
									id="video8"
									controls
									className={`${isVideo8Visible ? "" : "hide"}`}
									poster="../images/listening/Visceral 2C.jpg"
									src="../images/listening/Visceral 2C.mp4"
								></video> */}
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<section className="video">
								<img
									id="image1"
									src="../images/listening/article3_accent1_comp.jpg"
									alt="Enormous Library"
								/>
							</section>

							<p>
								At Lippincott, part of our discovery process involves getting to
								know a brand’s consumers. We spend days in qualitative
								interviews to understand their needs, motivations, desires, and
								challenges. We want to get inside their heads, understand the
								inner workings of their minds and emotions. It’s tremendously
								insightful, but it’s also time-consuming, limited, and somewhat
								ephemeral. So we wondered: could we use AI to do this in a
								better way?
							</p>

							<p>&nbsp;</p>
							<h4 className="section-subhead">
								Our goal: to find out if the tool could support our team during
								the discovery and validation phases of client work, with a
								particular focus on our qualitative Jobs to be Done (JTBD)
								research.
							</h4>
							<p>
								Jobs to be Done is a research framework popularized by Clay
								Christensen and our very own Taddy Hall. It explores why
								consumers use (or hire) products and services to get something
								(a job) done. Rather than just asking about the features and
								attributes that attract consumers, we explore their functional,
								social, and emotional motivations that encourage the things they
								do. We seek to understand the progress they are looking to make
								in their lives, and by understanding the “why” we can better
								design propositions that address the “how.”
							</p>
							<p>A JTBD interview consists of a few key features:</p>
							<ul>
								<li>
									<strong>The drill site</strong>, or the circumstance we wish
									to explore (e.g., preparing a home for guests, switching
									insurance carriers, choosing what to eat for lunch, etc.)
								</li>
								<li>
									<strong>The documentary</strong> dives deep, scene by scene,
									into the experience, starting from the moment the respondent
									realizes a need for the drill site and uncovering their
									thoughts and emotions experienced in each scene
								</li>
								<li>
									<strong>The debrief</strong> takes place in a collective
									discussion about the respondent’s needs, motivations, and
									challenges
								</li>
							</ul>
							<p>
								Each of these features plays a key role in enabling us to get to
								great insights, that turn into great ideas, that turn into great
								delivery. But they require deep, investigative conversation. The
								question that most interested us: Could a synthetic interviewer
								facilitate the level of deep, investigative conversation needed
								to deliver Jobs to Be Done insights?
							</p>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								Our experiment tested the hypothesis that we can use AI to
								engage real people in the same way we would in live one-on-one
								JTBD interviews—but at scale, on Reddit.
							</h4>
							<p>
								We engaged one of our current clients—a major insurer (think:
								car, homeowners, renters, etc.)—to try out this tool alongside
								our typical processes. We aimed to supplement or corroborate
								learnings gathered through traditional JTBD research to uncover
								how people consider and choose insurers, know when it’s time to
								switch, and what factors they care about most.
							</p>
							<p>
								Our traditional JTBD interviews yielded these themes and
								insights about what consumers are looking for from their
								insurers:
							</p>
							<img
								src="../images/listening/visceral_chart.png"
								alt="	1) Accelerate: Help me remove the barriers that slow me down so I can get back to what matters
	2) Embrace: Help me get the protection I need to celebrate my success and reduce my fears
	3) Maximize: Help me access all the benefits I expect at a lower-than-expected price
	4) Trust: Help me trust that you’ve got my back so I feel taken care of and never alone"
							/>
							<p>
								Could AI help reach similar insights by talking to Redditors for
								us?
							</p>

							<p>&nbsp;</p>
							<h4 className="section-subhead">
								We started by briefing our synthetic interviewer in natural
								language, just like we would brief a colleague.
							</h4>
							<video
								controls
								data-height="405"
								poster="../images/naming/video1.png"
								src="../images/listening/Visceral 1A.mp4"
							></video>
							<p>
								<strong>Our objective:</strong> We want to learn what motivates
								people to purchase car insurance. What are their social,
								emotional, and functional needs?
							</p>
							<p>
								<strong>Here’s who we want to speak to:</strong> Individuals
								with a keen interest in car insurance. They should be
								demographically and geographically diverse.
							</p>
							<p>
								<strong>Here’s where we want to find them:</strong> Reddit
								forums about car insurance, car ownership
							</p>
							<p>
								<strong>Here’s the circumstance they should be in:</strong> They
								should have purchased and discussed car insurance within the
								last year
							</p>
							<p>
								Once briefed, the AI interviewer went out and connected with
								hundreds of Redditors through direct messages.
							</p>

							<p>&nbsp;</p>
							<h4 className="section-subhead-smaller">
								Synthetic Interviewer:
								<br />
								<em>
									An AI agent that can conduct text-based interviews (e.g. via
									direct message on social media). It can interact with
									respondents, analyze their answers, and ask appropriate
									follow-ups, just like a live interviewer would.
								</em>
							</h4>
							<video
								controls
								data-height="335"
								poster="../images/naming/video2.png"
								src="../images/listening/Visceral 1B_Anon.mp4"
							></video>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								Our biggest “aha” was that Redditors were surprisingly reluctant
								to respond to DMs.
							</h4>

							<p>
								Of the hundreds of Redditors that we messaged, only 52
								replied—and those rarely elicited more than a sentence or two
								over two to three prompts. Those providing advice had a lot to
								say—but this advice was focused on the ‘what’ never the ‘why.’
							</p>
							<p>
								When digging for motivation, we rarely saw anything more than ‘I
								wanted a major player’, ‘they were the cheapest’, ‘my friend
								recommended it.’ We were much more likely to get superficial
								motivations (big player, price, recommendations) rather than the
								more substantive, ‘juicier’ motivations—the stuff of great brand
								foundations.
							</p>
							<p>
								While we corroborated baseline knowledge relevant to the
								discovery phase—price is important, trusted recommendations are
								important, brand awareness makes a difference—how could we
								elicit richer responses?
							</p>
							<p>
								We played with changing the personality of the agent— in modes
								of ‘looking for advice,’ to ‘researching about the topic,’ and
								finally explicitly into the mode of a researcher looking into
								consumer motivations surrounding the topic—to try to find the
								right tone that would generate engagement.
							</p>
							<video
								data-height="405"
								controls
								poster="../images/naming/video3.png"
								src="../images/listening/Visceral 1C.mp4"
							></video>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								In the end, we were unable to get to the level of detail that we
								would consider supplemental, let alone replicable.
							</h4>

							<p>
								This result is likely a limitation of the medium—Reddit DMs—with
								emphasis on the DMs part. Reddit is a great place to conduct
								social listening when it comes to insurance: 65% of social media
								users today say they prefer to get insurance advice from other
								ordinary people, not companies or agents; and Redditors are 43%
								more likely to purchase insurance based on reviews they read
								online (source: Reddit Global Insights, Cultural Primer:
								Insurance). Plus, we knew from our experimentation with another
								tool, Synthesio, that the majority of the conversation on
								insurance on social media happens on Reddit (vs. Twitter / X,
								Instagram, etc.). It might be the case that these conversations
								happen more publicly, rather than privately.
							</p>

							<p>
								We cannot fault the agent for the lack of responses. But the
								things we love about the JTBD process—the emotion, the
								engagement, the depth of thoughtfulness, candor, and
								insight—were missing from these conversations.
							</p>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								That said—there’s so much more to investigate here.
							</h4>
							<ul className="list-style">
								<li>
									Might we get more detailed and emotive responses by
									participating in the bigger conversations in Reddit—posting
									directly in threads instead of DMs?{" "}
								</li>
								<li>
									Could offering compensation for insight lead to a higher level
									of engagement?{" "}
								</li>
								<li>
									Is there a sort of “scraping+” model made up of both listening
									to the web via traditional “scraping” methods, supplemented by
									a few AI-directed DM-based conversations to prod on any
									patterns of interest?{" "}
								</li>
								<li>
									Are there other platforms outside Reddit we could leverage
									that would naturally lead to deeper back and forth
									conversations?{" "}
								</li>
								<li>
									Where does this tool really fit, in the spectrum of 1:1
									interview to general social listening?
								</li>
							</ul>

							<p>
								We’re unwilling to admit defeat. While we weren’t able to elicit
								the meaningful conversations with respondents that we’d hoped
								for and thus get the depth of insight we need, we don’t see this
								as the end.
							</p>

							<p>
								Rather, we’re intrigued by future possibilities—the answers to
								the questions we’ve posed here and the other questions those
								answers will inevitably raise. Until we get those, we aren’t
								crossing anything off the list.{" "}
							</p>

							<p>
								For now, though, we’ll stick to our tried-and-true method of
								person-to-person interviews for the highest-quality insights.
								When it comes to collecting data, that is.{" "}
							</p>

							<p>But&hellip;</p>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								What if we flipped the script and attempted to use AI as a
								virtual interviewee, rather than a virtual interviewer?
							</h4>

							<p>
								Stay tuned for our next experiment to find out whether we were
								able to use AI to develop a synthetic, durable representation of
								the target consumer that was capable of replicating human
								sentiment.
							</p>
							<p>
								<hr
									style={{
										border: "none",
										borderTop: "0.5px solid black",
										margin: "80px 0 40px",
									}}
								/>
								<a
									href="/replicating-human-sentiment"
									style={{ color: "black", textDecoration: "underline" }}
								>
									<strong>
										Continue to the next article in this series &gt;
									</strong>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* END YOUR CUSTOM MARKUP HERE */}

			<Footer />
		</div>
	);
};

export default ListeningTC;

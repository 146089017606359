import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./articles.css";
/**
 *
 */
const NamingNG = () => {
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	// Keep all left and right section refs in arrays
	const lSections = useRef([]);
	const rSections = useRef([]);

	// Methods to create refs when called upon DOM elements
	const pushLRef = (el) => lSections.current.push(el);
	const pushRRef = (el) => rSections.current.push(el);

	// Tracks video visibility. Set upon scroll position
	const [isVideo2Visible, setShowVideo2] = useState(true);
	const [isVideo3Visible, setShowVideo3] = useState(false);
	const [isVideo4Visible, setShowVideo4] = useState(false);
	const [isVideo5Visible, setShowVideo5] = useState(true);
	const [isVideo6Visible, setShowVideo6] = useState(false);

	useEffect(() => {
		let tl = gsap.timeline();
		let mm = gsap.matchMedia();
		// console.log(rSections);
		// console.log(lSections);
		const sectionsLength = lSections.current.length;

		for (let i = 0; i < sectionsLength; i++) {
			mm.add("(min-width: 992px)", () => {
				let startVal = "clamp(top top)";
				let endVal = "clamp(bottom center)";
				if (i < 2) {
					startVal = "clamp(top 64px)";
					endVal = "clamp(bottom bottom-400px)";
				}
				tl.to(rSections.current[i], {
					scrollTrigger: {
						pin: lSections.current[i],
						trigger: rSections.current[i],
						start: startVal,
						end: endVal,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						markers: false,
						// Tracks scroll progression (progress is a value of 0-1)
						// i value represents which left section is currently in view
						onUpdate: ({ progress }) => {
							// console.log("i: ", i, " || progress: ", progress.toFixed(2));
							if (i === 1) {
								if (progress >= 0.5) {
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(true);
								} else if (progress >= 0.3) {
									setShowVideo2(false);
									setShowVideo3(true);
									setShowVideo4(false);
								} else {
									setShowVideo2(true);
									setShowVideo3(false);
									setShowVideo4(false);
								}
							} else if (i === 2) {
								if (progress >= 0.6) {
									setShowVideo5(false);
									setShowVideo6(true);
								} else {
									setShowVideo5(true);
									setShowVideo6(false);
								}
							}
						},
					},
				});
			});
		}

		ScrollTrigger.sort();
		// ScrollTrigger.refresh();
	}, [lSections]);

	return (
		<div className="main-wrapper">
			<MainMenu />

			{/* BEGIN YOUR CUSTOM MARKUP HERE */}

			<div className="hero">
				<div className="left">
					<h1>Naming: The Next Generation</h1>
					<div className="info">
						<div className="breadcrumb">
							<a href>naming + ai</a>
						</div>
						<div className="tagging">
							<ul>
								<li>
									#Naming
								</li>
								<li>
									#Messaging
								</li>
								<li>
									#Voice
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="right">
					<p className="title">
						Play<span>Another Lippincott AI Exploration</span>
					</p>
					<section>
						<p>
							In case you missed it...Lippincott has been a leader in brand name
							development for 80 years, and creator of classic household brand
							names like Duracell, Sprite, and Off! and more recent
							market-changers like Kenvue, Corebridge, and Veralto. But as the
							limitations of language, trademark, name real estate and other
							stakes get even higher, AI represents a powerful opportunity to
							supercharge our naming power as long as we can manage for quality.
						</p>
						<p>
							For perspective, Lippincott typically generates 2,000 names
							against a project brief before narrowing to the 20 we present. But
							when you consider that Ai could access 217 billion other
							possibilities for names with 8 letters or less, it’s clear we may
							be missing some gems. An equally sobering counterpoint is that
							efforts to date point to an AI that is good at generating
							quantity, but bad at producing or selecting quality.
						</p>
						<p>
							So we’ve decided to play – to challenge this notion, by using more
							sophisticated prompts, deeper training, and test-and-learn
							methodology to see if we can get more viable results from AI.
						</p>
					</section>
				</div>
			</div>
			<div className="scrollingsections">
				{/* <!-- section one  --> */}
				<div className="baseline" id="section1">
					<div className="left">
						<h2 className="section-title">
							Experiment<span>01:</span>
						</h2>
					</div>
					<div className="right">
						<h3 className="section-title">Establishing the baseline</h3>
						<p>
							Starting with ChatGPT, we are setting out to understand the way
							the tool generates names without any help. How valuable are its
							“innate” instincts when looking to ideate new options for a brand
							name?
						</p>
						<p>
							So, let’s create a realistic prompt, but limit our guidance or
							framing to see how it does.
						</p>
					</div>
				</div>

				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<video
									id="video1"
									controls
									poster="../images/naming/video1.png"
									src="../images/naming/video1-baseline_fullscreen_01.ba9b05fc25b8f8b6d151.mp4"
								></video>
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<h3 className="section-headline">Results</h3>
							<section className="video">
								<video
									controls
									poster="../images/naming/video1.png"
									src="../images/naming/video1-baseline_fullscreen_01.ba9b05fc25b8f8b6d151.mp4"
								></video>
							</section>
							<h4 className="section-subhead">The good:</h4>
							<ul>
								<li>
									<b>It’s fast:</b> while experienced namers take several hours
									to generate a list of 100 names, GPT can produce that amount
									instantaneously
								</li>
								<li>
									<b>It’s accurate:</b> the names feel appropriate to the
									request, and tend to leverage familiar, and well-understood
									terms to ground names in the offering we’re defining. Even
									more, it demonstrates an understanding of a technical
									category, and the relevant words commonly used in the space.
								</li>
							</ul>

							<h4 className="section-subhead special">The bad:</h4>
							<ul>
								<li>
									<b>Literal and limited:</b> The concepts are highly literal
									and directly guided by words in the prompt, suggesting a lack
									of ability for abstraction or thinking more conceptually.
								</li>
								<li>
									<b>Lack of craft:</b> A bias toward compound “CamelCap” names
									that can feel dated, clunky, or less distinctive, the names
									don’t have the intangible feel of a good name that’s easy to
									say and remember.
								</li>
								<li>
									<b>Non-viable:</b> Generic terminology would be challenging to
									clear legal. We need to be able to stretch into less expected
									spaces to navigate the crowded trademark territories our
									clients occupy
								</li>
							</ul>

							<h4 className="section-subhead special">The opportunity:</h4>
							<p>
								It’s clear that GPT at baseline doesn’t give us what we need,
								but we have a number of options through creative prompting and
								context-setting that can help drive toward more interesting
								results.
							</p>

							<h4 className="block">
								Can we teach GPT different types of names? Can we train it to
								think more abstractly or creatively? Can we diversify the naming
								approaches or themes it explores? Can feeding it past examples
								or IC on our best practices help coach it to think more like we
								do about naming?
							</h4>
						</div>
					</div>
				</div>

				{/* <!-- section two  --> */}
				<div
					className="baseline baseline2"
					id="section2"
					data-section="#section2"
				>
					<div className="left">
						<h2 className="section-title">
							Experiment<span>02:</span>
						</h2>
					</div>
					<div className="right">
						<h3 className="section-title">Is the word “name” a problem?</h3>
						<p>
							One early observation as we’ve played with GPT’s naming
							capabilities: its go-to understanding of the word “name.”
						</p>
						<p>
							We first asked GPT to generate 10 “words” that abstractly evoke
							intelligence, and some of the results were interesting and could
							conceivably be used as, or modified into viable brand names.
						</p>
					</div>
				</div>

				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<video
									id="video2"
									className={`${isVideo2Visible ? "" : "hide"}`}
									controls
									poster="../images/naming/video2.png"
									src="../images/naming/video2-words_fullscreen_02A.b94b0e7d6715c80a324e.mp4"
								></video>
								<video
									id="video3"
									className={`${isVideo3Visible ? "" : "hide"}`}
									controls
									poster="../images/naming/video3.png"
									src="../images/naming/video3-words_fullscreen_02A.b94b0e7d6715c80a324e.mp4"
								></video>
								<video
									id="video4"
									className={`${isVideo4Visible ? "" : "hide"}`}
									controls
									poster="../images/naming/video4.png"
									src="../images/naming/video4-names_fullscreen_02B.3bea04b39895fe623c10.mp4"
								></video>
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<p>
								By contrast, when we asked ChatGPT to generate 10 “company
								names” that abstractly evoke intelligence, it only generated
								close-in terms, and defaulted to a compound format.
							</p>
							<h3 className="section-subhead suggested">
								This suggested an ingrained understanding of “name” as something
								that relies on expected category terms and a compound name
								convention.
							</h3>
							{/* Mobile Video */}
							<section className="video">
								<video
									controls
									poster="../images/naming/video2.png"
									src="../images/naming/video2-words_fullscreen_02A.b94b0e7d6715c80a324e.mp4"
								></video>
							</section>
							<p>
								While compound names are indeed common among brand names across
								all categories (as exemplified by PayPal, YouTube, Salesforce,
								Facebook, etc.) many of the most famous, aspirational brand
								names use shorter, more distinctive names. Whether they are
								coined/invented names (Google, Hulu, Zappos, Xerox) or real,
								abstract dictionary words (Amazon, Apple, Peloton, Uber), it
								seems fair to say that ChatGPT is not primed take you there and
								in fact, requires substantial handholding to do so.
							</p>
						</div>

						<div>
							{/* Mobile Video */}
							<section className="video">
								<video
									controls
									poster="../images/naming/video3.png"
									src="../images/naming/video3-words_fullscreen_02A.b94b0e7d6715c80a324e.mp4"
								></video>
							</section>
							<h4 className="section-subhead special lesson">
								The lesson learned?
							</h4>
							<ul>
								<li>
									We may be better off asking ChatGPT for “words” or “ideas” as
									opposed to “names” when trying to name a brand, helping it
									garner results more aligned to the range of possibilities.
								</li>
								<li>
									Better yet, we can teach it to understand the different types
									of names through more detailed prompts, in order to be more
									targeted in our exploration.
								</li>
							</ul>

							<p>
								How do we get to more detailed prompts? We can do this by using
								our name criteria process to pinpoint the most relevant name
								type for a given brand based on conventions and white space in
								the category, the intended tone and level of clarity, or even
								the idea that a certain type of name conveys.
							</p>
						</div>

						<div>
							<h4 className="block refined">
								When we refined the prompt for a coined name, we found ChatGPT
								was able to provide original, single words that felt more like
								names:
							</h4>
							{/* Mobile Video */}
							<section className="video">
								<video
									controls
									poster="../images/naming/video4.png"
									src="../images/naming/video4-names_fullscreen_02B.3bea04b39895fe623c10.mp4"
								></video>
							</section>
							<h2 className="section-heading-larger">Results</h2>
							<p>
								While these results may not reflect the most viable names, they
								quickly demonstrate that through simple priming around a certain
								type of name and the intention behind it, ChatGPT is able to
								deliver a more applicable set of name options.
							</p>
							<p>
								Let's play on to see how this priming technique can be further
								expanded and refined to deliver even more interesting results.
							</p>
						</div>
					</div>
				</div>

				{/* <!-- section three  --> */}
				<div
					className="baseline baseline3"
					id="section3"
					data-section="#section3"
				>
					<div className="left">
						<h2 className="section-title">
							Experiment<span>03:</span>
						</h2>
					</div>
					<div className="right">
						<h3 className="section-title">
							GPT is literally, so literal. Can we teach it abstraction?
						</h3>
						<p>
							If you've ever asked ChatGPT to create names, you know it tends to
							over-index on close-in, expected category terms and a compound
							name style. For example, when we've asked it to generate names for
							a tech brand that suggests Intelligence, it will take terms that
							literally connect to intelligence, cognition, or brain activity
							and then randomly pair them together.
						</p>
					</div>
				</div>

				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<video
									id="video5"
									className={`${isVideo5Visible ? "" : "hide"}`}
									controls
									poster="../images/naming/video5.png"
									src="../images/naming/video5-abstract_fullscreen_03C.dae29d420df3bdfce453.mp4"
								></video>
								<video
									id="video6"
									className={`${isVideo6Visible ? "" : "hide"}`}
									controls
									poster="../images/naming/video6.png"
									src="../images/naming/video6-abstract_fullscreen_03C.dae29d420df3bdfce453.mp4"
								></video>
							</section>
						</div>
					</div>

					<div className="right third" ref={pushRRef}>
						<div>
							<h3 className="section-subhead">
								Requesting ChatGPT to provide more “abstract” names (in an
								effort to get to fresher terms) doesn't solve the problem.
							</h3>
							<section className="video">
								<video
									controls
									poster="../images/naming/video5.png"
									src="../images/naming/video5-abstract_fullscreen_03C.dae29d420df3bdfce453.mp4"
								></video>
							</section>
							<p>
								Rather than leveraging a broader range of ideas and metaphors
								that could express intelligence in less obvious ways, the tool
								literally force-fits abstraction into its name options like
								EngimaTech, Cosmic Technologies and Nebula.
							</p>

							<p>
								When we compare these results to suggestive brand names like
								Oracle, Intuit, Sage, and even more abstract names like Apple,
								Google, Arista or with invented names like Palantir, Equinix, or
								Synopsys, ChatGPT is not even scratching the surface of the
								range of possible creative ideas that can evoke intelligence.
							</p>

							<p>
								In an attempt to overcome this limitation, we tested a wide
								array of prompts trying to explain metaphor, abstraction, and
								thematic imagery, all with similar results. But asking ChatGPT
								to “imagine” just yielded names like dream, reverie, and…
								imagine.
							</p>

							<h3 className="section-subhead">
								Finally, a breakthrough: “creative chaining”
							</h3>

							<p>
								Our “aha moment” was when we came upon the idea to dissect the
								creative process and literally spell out how it could be
								sequenced for ChatGPT. If we think about why the name Oracle
								suggests intelligence, it's because oracles are associated with
								predicting the future. Predicting the future, is an action that
								requires omniscience, and therefore it conveys to us a notion of
								impressive intellect. Making that mental leap requires a
								chaining of associations, i.e. Intelligence &gt; insight&gt;
								prediction&gt; oracle, and this seemed like something we could
								teach ChatGPT to do!
							</p>
						</div>

						<div>
							<section className="video">
								<video
									controls
									poster="../images/naming/video6.png"
									src="../images/naming/video6-abstract_fullscreen_03C.dae29d420df3bdfce453.mp4"
								></video>
							</section>
							<h3 className="section-subhead-larger">Results</h3>
							<ol>
								<li>
									We were reaching a point where the words were less expected,
									but still had a root in themes of intelligence. Prior tests
									would not have yielded words like Quill, Marble, Canvas, or
									Hive – but through creative chaining, we got GPT there
								</li>
								<li>
									When asking GPT to show its chains, we could observe the
									“thought” progression, and intermediate words like Workshop
									and Symphony, too, elicited interesting creative pathways. The
									first word in every chain was more akin to the prior
									“abstract” results GPT created, but the chains grew
									progressively more “creative”
								</li>
								<li>
									GPT was able to assimilate the idea of “creative chaining” so
									subsequent requests within the same thread, we were able to
									ask for a new series of creative chain, or even just for the
									4th word in each chain
								</li>
							</ol>

							<p>
								BUT, challenges remain: we’ve achieved a route to abstraction,
								but these are still real words, unlikely to be available in many
								trademark categories. And the process broke down when we asked
								GPT to translate these real, dictionary words to more unique
								approaches like compound or coined names. Also, interestingly,
								after repeating a couple of times, we saw the results degrade –
								as though GPT was running out of ideas, or regressing to the
								notion where “abstract” becomes literally mysterious
								again&hellip;
							</p>

							<ul className="tagging">
								<li>
									Intelligence &gt; Creativity &gt; Innovation &gt; Ingenuity
									&gt; Inventor
								</li>
								<li>
									Intelligence &gt; Perception &gt; Awareness &gt; Consciousness
									&gt; Cognition
								</li>
								<li>
									Intelligence &gt; Science &gt; Experiment &gt; Hypothesis &gt;
									Theory
								</li>
								<li>
									Intelligence &gt; Mystery &gt; Enigma &gt; Riddle &gt; Puzzle
								</li>
							</ul>

							<p className="section-subhead block origination">
								To keep GPT in a truly originative space, we need to reset our
								prompts frequently, and keep pushing toward more freedom of
								association.
							</p>

							<p>
								While these results may not reflect the most viable names, we’ve
								quickly demonstrated that through simple priming around a
								certain type of name, and the intention behind it, GPT is able
								to apply this guidance to deliver a set of options with a more
								focused approach.
							</p>

							<p>
								Let’s play on to see how this priming technique can be further
								expanded and refined to deliver even more interesting results.
							</p>
							<p>
                				<hr style={{ border: 'none', borderTop: '0.5px solid black', margin: '80px 0 40px' }} />
                				<a href="/can-ai-be-used-to-better-understand-humans" style={{ color: 'black', textDecoration: 'underline' }}>
                				<strong>Continue to the next series on AI + Consumer Research &gt;</strong>
                				</a>
              				</p>
						</div>
					</div>
				</div>
			</div>
			{/* END YOUR CUSTOM MARKUP HERE */}

			<Footer />
		</div>
	);
};

export default NamingNG;

import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import STRINGS from "./constants/strings";
import { motion, useScroll, cubicBezier, useTransform } from "framer-motion";
import { sizes } from "./constants/devices";
import PBlockMenu from "./PBlockMenu";
import { OpacityHeading } from "../interactions/OpacityContent";
import { TransformingTextBox } from "../interactions/TransformingContent";
import { TypeAnimation } from "react-type-animation";
import "./StyleWelcome.css";
import "./StyleRegenerateMenu.css";

const PBlockContainer = styled(motion.section).attrs({
  className: "regenerateContainer",
})`
  background-image: url(${(props) => props.$backgroundImage});
`;

const PBlockOverlay = styled(motion.div)`
  //position: sticky;
  min-height: 100vh;
  width: 100%;
  background-color: black;
  position: absolute;
  // background-image: url(${(props) => props.$backgroundImage});
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
  z-index: -2;
`;

const PBlockContentWrapper = styled.div.attrs({
  className: "regenerateContentWrapper",
})``;

const PBlockContent = styled(motion.h2)`
  font-family: "Noe Display";
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  max-width: 880px;
  padding: 0rem 6rem;
  color: white;
  margin: 0;

  @media only screen and (max-width: 1024px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    max-width: 74%;
    padding: 0rem 5rem;
  }
  @media only screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const TextWrapper = styled(motion.div).attrs({
  className: "regenerateText",
})``;

const RegenerateBadge = styled.div`
  font-family: "B612 Mono", monospace;
  padding: 0.6rem 1.1rem;
  border-radius: 4px;
  font-size: 3.2rem;
  background: rgba(0, 0, 0, 1);
  color: white;
`;

export const PBlockSubheader = styled(motion.p)`
  margin: 0;
  font-family: "Noto Sans";
  font-size: 2rem;
  text-align: center;
  color: white;
  max-width: 700px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    max-width: 80%;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    max-width: 80%;
  }
`;

const ParagraphBlock = ({
  backgroundImage,
  contentString,
  subHeaderString,
  scrollYArray,
  opacityArray,
  opacityOverride,
  scrollOffset,
  handlePlayerChange,
  hasMenu,
  scrollInfo,
  selectedPlayer,
  glitchImage,
  sectionHeight,
}) => {
  //const [subHeaderVisible, setSubHeaderVisible] = useState(true);
  const [glitchVisible, setGlitchVisible] = useState(false);
  const ref = useRef(null);
  const contentRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: scrollOffset,
  });
  const opacity = useTransform(scrollYProgress, scrollYArray, opacityArray);

  const scrollToRef = useRef(null);
  useEffect(() => {
    const mainNav = document.querySelector(".mainNav");
    const body = document.querySelector("body");
    if (glitchVisible) {
      scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
      // mainNav.style.transform = "translateY(-120%)";
      body.style.overflow = "hidden";
    } else {
      mainNav.style.transform = "";
      body.style.overflow = "";
    }
  }, [glitchVisible]);

  const handleBackgroundChange = () => {
    setGlitchVisible(true);
    setTimeout(() => {
      setGlitchVisible(false);
      //window.scrollTo() <-- use window.innerheight to calculate beginning of new section
    }, 1500);
  };

  const GlitchImage = (
    <PBlockContainer $backgroundImage={glitchImage} style={{ zIndex: 2 }}>
      <PBlockContentWrapper>
        <RegenerateBadge>...regenerating content...</RegenerateBadge>
      </PBlockContentWrapper>
    </PBlockContainer>
  );

  const SubHeader = (
    <PBlockSubheader
      key={subHeaderString}
      // initial="hidden"
      // whileInView="visible"
      // style={{
      //     display: subHeaderVisible ? 'block' : 'none'
      // }}
      // transition={{
      //     duration: 1,
      //     delay: opacity * 0.5,
      //     ease: cubicBezier(0.3,0,0.1,1)
      // }}
      // variants={{
      //     visible: { opacity: 1, y: 0 },
      //     hidden: { opacity: 0, y: 200 }
      // }}
    >
      <TypeAnimation
        key={subHeaderString}
        splitter={(str) => str.split(/(?= )/)}
        sequence={[2600, subHeaderString]}
        speed={60}
        omitDeletionAnimation={true}
        repeat={1}
      />
    </PBlockSubheader>
  );

  const NormalContent = (
    <TextWrapper key={contentString} $hasMenu={hasMenu}>
      <PBlockContent
        initial="hidden"
        whileInView="visible"
        transition={{
          duration: 1.5,
          delay: opacity * 0.6,
          ease: cubicBezier(0.3, 0, 0.1, 1),
        }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        <TypeAnimation
          key={contentString}
          splitter={(str) => str.split(/(?= )/)}
          sequence={[contentString, 1000]}
          speed={60}
          omitDeletionAnimation={true}
          repeat={1}
        />
      </PBlockContent>
      {SubHeader}
    </TextWrapper>
  );

  const PBlock = (
    <PBlockContainer
      $backgroundImage={backgroundImage}
      $sectionHeight={sectionHeight}
      ref={ref}
    >
      {/* <PBlockBackground $backgroundImage={backgroundImage} /> */}
      <PBlockOverlay
        style={{
          opacity:
            opacityOverride || opacityOverride === 0
              ? opacityOverride
              : opacity,
        }}
      />
      <PBlockContentWrapper ref={contentRef}>
        {/* {(isVisible && typed) ? typedContent : NormalContent} */}
        {/* {NormalContent} */}
        {hasMenu ? (
          NormalContent
        ) : (
          <TransformingTextBox
            positions={[0, 0, 0, 0]}
            scrollInfo={scrollInfo}
            alignment={"center"}
            child={
              <OpacityHeading
                scrollInfo={scrollInfo}
                simpleFade={true}
                baseOpacity={0}
                text={[contentString]}
                subHeader={STRINGS.P_BLOCK_DEFAULT_SUB}
              />
            }
          />
        )}
        {hasMenu && (
          <PBlockMenu
            selectedPlayer={selectedPlayer}
            handlePlayerChange={handlePlayerChange}
            //handleSubHeaderChange={handleSubHeaderChange}
            handleBackgroundChange={handleBackgroundChange}
          />
        )}
      </PBlockContentWrapper>
    </PBlockContainer>
  );

  return glitchVisible ? GlitchImage : PBlock;
};

export default ParagraphBlock;

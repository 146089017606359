import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import "./KeepExploring.css";

const articles = [
	{
		route: "/lilah",
		bgimage: "images/lilah/gundy1479_A_futuristic_library_with_many_people_walking_around__dffc0ba9-530f-420d-9b8b-22466e9f083e%201.jpg",
		title: "Introducing Lilah",
	},
	{
		route: "/replicating-human-sentiment",
		bgimage: "images/replicating/gundy1479_A_futuristic_library_with_many_books_and_crowded_peop_0767a76b-ceab-4013-a86e-d1b39b48721b%201.png",
		title: "Replicating human sentiment",
	},
	{
		route: "/from-listening-to-conversing-consumers-reddit-and-jtbd",
		bgimage: "images/listening/article3_header_comp.jpg",
		title: "From listening to conversing: Consumers, Reddit, and JTBD",
	},
	{
		route: "/listening-live",
		bgimage: "images/listening-live/article2_header_comp.jpg",
		title: "Listening live",
	},
	{
		route: "/can-ai-be-used-to-better-understand-humans",
		bgimage: "images/humans/article1_header_comp.jpg",
		title: "Can AI be used to better understand humans?",
	},
	{
		route: "/naming-the-next-generation",
		bgimage: "images/footer/secondArticleCard.jpg",
		title: "Naming: The Next Generation",
	},
	{
		route: "/applying-the-power-of-ai-to-naming",
		bgimage: "images/footer/articleCard.jpg",
		title: "Applying the power of AI to naming",
	},
	{
		route: "/",
		bgimage: "images/p-block-1.jpg",
		title: "Welcome to Play",
	},
];

const ExploringCard = styled(Link).attrs((props) => ({
	className: "exploringCard curtain",
	to: props.to,
}))`
	background-image: url(${(props) => props.bgimage});
	margin-right: 10px;
	border-radius: 20px;
	display: flex;
	justify-content: flex-start; /* Change to flex-start for left alignment */
	align-items: center;
	padding-left: 20px; /* Add left padding for spacing */

	h4 {
		text-align: left; /* Align the title text to the left */
	}
`;

const KeepExploring = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [location.pathname]);

	const getCardDetails = () => {
		return articles.filter((article) => article.route !== location.pathname);
	};

	const cards = getCardDetails();

	return (
		<div className="keepExploring">
			<div className="footerWrapper">
				<div className="dFlex flexColumn">
					<h3 className="noe50px">Keep exploring</h3>
					<div className="cardContainer">
						<div className="dFlex" style={{ justifyContent: "space-evenly" }}>
							{cards.map(({ route, title, bgimage }) => (
								<ExploringCard key={title} to={route} bgimage={bgimage}>
									<h4 className="noe60px">{title}</h4>
								</ExploringCard>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KeepExploring;

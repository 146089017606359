import React, { useRef, useState } from "react";
import styled from "styled-components";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import Demo from "../components/Demo";
import "./StylePageNaming.css";

/// FRAMER MOTION ///
import { motion, useScroll, useTransform } from "framer-motion";
import { useMediaQuery } from "@react-hook/media-query";

// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile,
// } from "react-device-detect";

const HeroNaming = styled.div.attrs({
  className: "heroNaming",
})`
  background-image: url(${process.env
      .PUBLIC_URL}/images/pages/naming/top-heart.webp),
    url(${process.env.PUBLIC_URL}/images/pages/naming/stairway.webp);
`;
const PlayTagline = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/play-ai-tagline.svg",
})``;

// 3D images ====================================================
const FloatingImageOne = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/phone.webp",
  className: "floatingImage verizonPhone",
})``;
const FloatingImageTwo = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/off.webp",
  className: "floatingImage offJhonson",
})``;
const FloatingImageThree = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/duracell.webp",
  className: "floatingImage duracell",
})``;
const FloatingImageFour = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/sprite.webp",
  className: "floatingImage sprite",
})``;
const FloatingImageFive = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/robotott.webp",
  className: "floatingImage robotott",
})``;
const FloatingImageSix = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/dog.webp",
  className: "floatingImage dog3D",
})``;
const FloatingImageEigth = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/guitar.webp",
  className: "floatingImage guitar3D",
})``;
const FloatingImageTen = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/touros.webp",
  className: "floatingImage touros",
})``;
const FloatingImageEleven = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/sphere.webp",
  className: "floatingImage sphere",
})``;
const FloatingImageHotDog = styled.img.attrs({
  src: process.env.PUBLIC_URL + "/images/pages/naming/hotdog.webp",
  className: "floatingImage demoImage",
})``;

const PowerOfNaming = () => {
  const [showDemo, setShowDemo] = useState(false);

  /// IMAGES ///
  const imagesContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: imagesContainer,
    offset: ["0 1", ".8 1"],
  });

  const verizonPhoneTop = useTransform(
    scrollYProgress,
    [0, 1],
    ["20%", "-100%"],
    {
      clamp: false,
    }
  );

  const is480 = useMediaQuery("only screen and (max-width: 480px)");
  const is768 = useMediaQuery("only screen and (max-width: 768px)");
  const is1000 = useMediaQuery("only screen and (max-width: 1000px)");
  const is950 = useMediaQuery("only screen and (max-width: 950px)");
  const is1280 = useMediaQuery("only screen and (max-width: 1280px)");

  const offJhonsonTop = useTransform(scrollYProgress, [0, 1], ["70%", "-90%"], {
    clamp: false,
  });
  const duracellTop = useTransform(
    scrollYProgress,
    [0, 1],
    ["130%", is768 ? "-70%" : "-40%"],
    {
      clamp: false,
    }
  );
  const spriteTop = useTransform(scrollYProgress, [0, 1], ["155%", "-35%"], {
    clamp: false,
  });
  const robotTop = useTransform(
    scrollYProgress,
    [0, 1],
    ["280%", is768 ? "60%" : is950 ? "48%" : is1280 ? "40%" : "20%"],
    {
      clamp: false,
    }
  );
  const dogTop = useTransform(
    scrollYProgress,
    [0, 1],
    ["360%", is768 ? "55%" : is1280 ? "45%" : "30%"],
    {
      clamp: false,
    }
  );

  const hotDogTop = useTransform(
    scrollYProgress,
    [0, 1],
    [
      showDemo ? (is480 ? "60%" : is1000 ? "65" : "50%") : "400%",
      is768 ? "70%" : is950 ? "62%" : is1280 ? "57%" : "35%",
    ],
    {
      clamp: false,
    }
  );

  const guitarTop = useTransform(
    scrollYProgress,
    [0, 1],
    ["500%", is950 ? "60%" : is1280 ? "53%" : "35%"],
    {
      clamp: false,
    }
  );

  const tourosTop = useTransform(
    scrollYProgress,
    [0, 1],
    ["600%", is950 ? "84%" : is1280 ? "75%" : "67%"],
    {
      clamp: false,
    }
  );

  const sphereTop = useTransform(
    scrollYProgress,
    [0, 1],
    ["700%", is768 ? "80%" : is1280 ? "86%" : "80%"],
    {
      clamp: false,
    }
  );
  const sphereTopMob = useTransform(scrollYProgress, [0, 1], ["700%", "100%"], {
    clamp: false,
  });

  return (
    <div className="main-wrapper">
      <MainMenu />
      <div className="namingWrapper">
        <HeroNaming>
          <div className="tagline">
            <PlayTagline />
          </div>
          <div className="contentWrapper">
            <h1 className="pageTitle">
              Can the power of AI be applied to the commercial naming industry?
            </h1>
          </div>
          <div className="contentWrapper breadCrumbs">
            <div className="breadCrumb">
              <span>naming + ai</span>

            </div>
            <div className="hastag">#Naming #Messaging #Voice</div>
          </div>
        </HeroNaming>
        <div
          className={showDemo ? "topSection showDemo" : "topSection"}
          ref={imagesContainer}
        >
          <div className="contentWrapper contentWrapperDesktop">
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  width: "50%",
                  height: "100vh",
                }}
              >
                <motion.img
                  className="floatingImage verizonPhone"
                  src={
                    process.env.PUBLIC_URL + "/images/pages/naming/phone.webp"
                  }
                  style={{
                    top: verizonPhoneTop,
                  }}
                />
                {/* OFF */}
                <motion.img
                  className="floatingImage offJhonson"
                  src={process.env.PUBLIC_URL + "/images/pages/naming/off.webp"}
                  style={{
                    top: offJhonsonTop,
                  }}
                />
                {/* DURACELL */}
                <motion.img
                  className="floatingImage duracell"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/pages/naming/duracell.webp"
                  }
                  style={{
                    top: duracellTop,
                  }}
                />

                {/* SPRITE */}
                <motion.img
                  className="floatingImage sprite"
                  src={
                    process.env.PUBLIC_URL + "/images/pages/naming/sprite.webp"
                  }
                  style={{
                    top: spriteTop,
                  }}
                />

                {/* HotDog */}
                <motion.img
                  className="floatingImage demoImage"
                  src={
                    process.env.PUBLIC_URL + "/images/pages/naming/hotdog.webp"
                  }
                  style={{
                    top: hotDogTop,
                  }}
                />

                {/* robotott */}
                <motion.img
                  className="floatingImage robotott"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/pages/naming/robotott.webp"
                  }
                  style={{
                    top: robotTop,
                  }}
                />

                {/* Dog */}
                <motion.img
                  className="floatingImage dog3D"
                  src={process.env.PUBLIC_URL + "/images/pages/naming/dog.webp"}
                  style={{
                    top: dogTop,
                  }}
                />

                {/* guitar3D */}
                <motion.img
                  className="floatingImage guitar3D"
                  src={
                    process.env.PUBLIC_URL + "/images/pages/naming/guitar.webp"
                  }
                  style={{
                    top: guitarTop,
                  }}
                />

                {/* Touros */}
                <motion.img
                  className="floatingImage touros"
                  src={
                    process.env.PUBLIC_URL + "/images/pages/naming/touros.webp"
                  }
                  style={{
                    top: tourosTop,
                  }}
                />

                {/* Sphere */}
                <motion.img
                  className="floatingImage sphere"
                  src={
                    process.env.PUBLIC_URL + "/images/pages/naming/sphere.webp"
                  }
                  style={{
                    top: sphereTop,
                  }}
                />
              </div>
              <div
                className="textContent"
                style={{
                  height: "300vh",
                  marginTop: "-600px",
                }}
              >
                <div className="namingParagraph">
                  <p>
                    If you've uttered the name Sprite, Wisk, Duracell, Citgo or
                    Verizon recently &mdash; you have the naming wizardry of
                    
                    Lippincott to thank for it. You see, we know a thing or two
                    about naming companies &mdash; we've been creating standout names for
                    80 years.
                  </p>
                </div>
                <div className="namingTitle40px">
                  Naming might just be one of the ripest territories for AI
                  enhancement, but how do we invite automation in without killing
                  creativity?
                </div>
                <div className="namingParagraph">
                  But today, naming breakout businesses takes more than a
                  “thesaurus and a spreadsheet.” The analog days of name
                  development are long past. Today, with 64.4 million active
                  trademarks, and an average adult vocabulary of 30,000 words,
                  the bar is high and the road often long and windy as you try
                  to find the perfect name in the proverbial haystack.
                  <br />
                  <br />
                  Finding the right name involves many steps...
                </div>
                <ul className="namingQuote">
                  <li>Concept generation</li>
                  <li>Name refinement</li>
                  <li>Linguistic strategy</li>
                  <li>Legal navigation</li>
                  <li>Validation...</li>
                </ul>
                <div className="namingParagraph">
                  Naming businesses has gotten pretty advanced over the years.
                  Each step in our process has a specific outcome and reason to
                  exist. Experimenting with the different steps of naming, it's
                  easy to see all of the different ways it can be enhanced with
                  AI.
                </div>
                <div className="namingTitle40px">
                  So, we asked ourselves, "what part of our naming process
                  should we AI-ify?"
                </div>
                <div className="namingParagraph">
                  One of the best ways to learn is through experimentation and
                  PLAY. Turns out playing with AI-powered naming can yield some
                  interesting results. Click around this fun demo of the kinds
                  of results we got from our naming experiments with popular
                  GPTs.
                </div>
                <div style={{ marginTop: "300px" }}>
                  <Demo
                    showDemo={showDemo}
                    onSetShowDemo={(val) => setShowDemo(val)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* MOBILE */}
          <div className="contentWrapper contentWrapperMobile">
            <div className="textContent">
              <div className="namingParagraph">
                <p>
                  If you've uttered the name Sprite, Wisk, Duracell, Citgo or
                  Verizon recently &mdash; you have the naming wizardry of
                  
                  Lippincott to thank for it. You see, we know a thing or two
                  about naming companies &mdash; we've been creating standout names for 80
                  years.
                </p>
              </div>
              <div className="namingTitle40px">
                Naming might just be one of the ripest territories for AI
                enhancement, but how do we invite automation in without killing
                creativity?
              </div>
              <div className="namingParagraph">
                But today, naming breakout businesses takes more than a
                “thesaurus and a spreadsheet.” The analog days of name
                development are long past. Today, with 64.4 million active
                trademarks, and an average adult vocabulary of 30,000 words, the
                bar is high and the road often long and windy as you try to find
                the perfect name in the proverbial haystack.
                <br />
                <br />
                Finding the right name involves many steps...
              </div>
              <ul className="namingQuote">
                <li>Concept generation</li>
                <li>Name refinement</li>
                <li>Linguistic strategy</li>
                <li>Legal navigation</li>
                <li>Validation...</li>
              </ul>
              <div className="namingParagraph">
                Naming businesses has gotten pretty advanced over the years.
                Each step in our process has a specific outcome and reason to
                exist. Experimenting with the different steps of naming, it's easy
                to see all of the different ways it can be enhanced with AI.
              </div>
              <div className="namingTitle40px">
                So, we asked ourselves, "what part of our naming process should
                we AI-ify?"
              </div>
              <div className="namingParagraph">
                One of the best ways to learn is through experimentation and
                PLAY. Turns out playing with AI-powered naming can yield some
                interesting results. Click around this fun demo of the kinds of
                results we got from our naming experiments with popular GPTs.
              </div>
            </div>
            <FloatingImageOne />
            <FloatingImageTwo />
            <FloatingImageThree />
            <FloatingImageFour />
            <FloatingImageFive />
            <FloatingImageSix />

            <Demo
              showDemo={showDemo}
              onSetShowDemo={(val) => setShowDemo(val)}
            />

            <FloatingImageHotDog />
            <FloatingImageEigth />
            <FloatingImageTen />
            <FloatingImageEleven />
          </div>
        </div>
      </div>
      <div className="meetFirmi">
        <div className="firmiWrapper">
          <div className="firmiColumnOne">
            <div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/pages/naming/firmi-label.webp"
                }
                alt=""
              />
            </div>
          </div>
          <div className="firmiColumnTwo">
            <div className="twoTextWrap paddingTextOne">
              <h2>Meet Firmi</h2>
              <p>
                Would you believe we've been using AI in our naming exploration
                since 2018? That's when we first developed a proprietary
                AI-trained naming tool to augment our human-led name generation.
              </p>
              <h3>
                Firmi, our first AI tool, was originally trained on 50,000 names
                and created over 50+ Lippincott projects that embodied best
                practices and had passed legal viability filters over the years.
              </h3>
              <p>
                And the first name it developed &ndash; its own &ndash; did not disappoint.
                <br />
                <br />
                The name Firmi (inspired in the idea of the “firm's
                intelligence,” plus an ability to “engineer” new names in a nod
                to physicist Enrico Fermi), led to this ownable and relevant gem.
                And we still use it today.
              </p>
              <p>
                Firmi has come a long way since those days, and we continue to
                develop it today. Take a peek at Firmi in action below.{" "}
              </p>
              <h3>
                Firmi is now leaps and bounds ahead of where it started. Check
                it out:
              </h3>
              <div className="videoContainer">
                <video
                  src={`${process.env.PUBLIC_URL}/images/pages/naming/firmiVideo.mp4`}
                  poster={`${process.env.PUBLIC_URL}/images/pages/naming/firmiVideo.webp`}
                  controls
                  autoplay
                  muted
                  playsinline
                  onClick={({ currentTarget }) => {
                    if (currentTarget.paused) {
                      currentTarget.play();
                    } else {
                      currentTarget.pause();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="firmiWrapper">
          <div className="firmiColumnOne second">
            <div>
              <h2>Our AI Naming Objectives:</h2>
            </div>
          </div>
          <div className="firmiColumnTwo">
            <div className="twoTextWrap">
              <div className="firmiDivider"></div>
              <ul>
                <li>
                  Which use cases is it great at? Where does it have
                  limitations?
                </li>
                <li>
                  Beyond mere generation, can it help refine, iterate, or even
                  select optimal options?
                </li>
                <li>
                  How do we balance the risks of AI with the immense upside to
                  our creative capabilities?
                </li>
                <li>
                  How do we build Firmi 2.0, and other tools, to take
                  advantage of the opportunity?
                </li>
              </ul>
              <p>
                As we keep testing the hypothesis that this GPT era can
                supercharge our expertise and creative processes to arrive at
                stronger names than ever before, we are uncovering all kinds of
                new ways to apply AI to the craft of naming.
              </p>
              <p>
                <hr style={{ border: 'none', borderTop: '0.5px solid white', margin: '80px 0 40px' }} />
                <a href="/naming-the-next-generation" style={{ color: 'white', textDecoration: 'underline' }}>
                <strong>Continue to the next article in this series &gt;</strong>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PowerOfNaming;

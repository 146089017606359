import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./articles.css";
/**
 *
 */
const ListeningLive = () => {
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	// Keep all left and right section refs in arrays
	const lSections = useRef([]);
	const rSections = useRef([]);

	// Methods to create refs when called upon DOM elements
	const pushLRef = (el) => lSections.current.push(el);
	const pushRRef = (el) => rSections.current.push(el);

	// Tracks video visibility. Set upon scroll position
	const [isVideo1Visible, setShowVideo1] = useState(true);
	const [isVideo2Visible, setShowVideo2] = useState(false);
	const [isVideo3Visible, setShowVideo3] = useState(false);
	const [isVideo4Visible, setShowVideo4] = useState(false);
	const [isVideo5Visible, setShowVideo5] = useState(false);
	const [isVideo6Visible, setShowVideo6] = useState(false);

	useEffect(() => {
		let tl = gsap.timeline();
		let mm = gsap.matchMedia();
		const sectionsLength = lSections.current.length;

		for (let i = 0; i < sectionsLength; i++) {
			mm.add("(min-width: 992px)", () => {
				let startVal = "clamp(top top)";
				let endVal = "clamp(bottom center)";
				if (i < 2) {
					startVal = "clamp(top 64px)";
					endVal = "clamp(bottom bottom-400px)";
				}
				tl.to(rSections.current[i], {
					scrollTrigger: {
						pin: lSections.current[i],
						trigger: rSections.current[i],
						start: startVal,
						end: endVal,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						markers: false,
						// Tracks scroll progression (progress is a value of 0-1)
						// i value represents which left section is currently in view
						onUpdate: ({ progress }) => {
							// console.log("i: ", i, " || progress: ", progress.toFixed(2));
							if (i === 0) {
								if (progress >= 0.85) {
									//show video 6
									// console.log("show video 6");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(false);
									setShowVideo6(true);
								} else if (progress >= 0.7) {
									//show video 5
									// console.log("show video 5");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(true);
									setShowVideo6(false);
								} else if (progress >= 0.55) {
									//show video 4
									// console.log("show video 4");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(true);
									setShowVideo5(false);
									setShowVideo6(false);
								} else if (progress >= 0.4) {
									//show video 3
									// console.log("show video 3");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(true);
									setShowVideo4(false);
									setShowVideo5(false);
									setShowVideo6(false);
								} else if (progress >= 0.3) {
									//show video 2
									// console.log("show video 2");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(true);
									setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(false);
									setShowVideo6(false);
								} else {
									//show video 1
									// console.log("show video ");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(true);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(false);
									setShowVideo6(false);
								}
							}
						},
					},
				});
			});
		}

		ScrollTrigger.sort();
	}, [lSections]);

	return (
		<div className="main-wrapper">
			<MainMenu />

			{/* BEGIN YOUR CUSTOM MARKUP HERE */}

			<div className="hero light-mode">
				<div className="left listening-live curtain">
					<h1>
						<div className="eyebrow">Experiment 01:</div>
						Listening live
					</h1>
					<div className="info">
						<div className="breadcrumb">Consumer Research + ai</div>
						<div className="tagging">
							<ul>
								<li>#Consumerresearch</li>
								<li>#Insights</li>
								<li>#Brand</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="right">
					<p className="title">
						Play<span>Another Lippincott AI Exploration</span>
					</p>
					<section>
						<p className="no-cap">Dr. Livingstone, I presume.</p>
						<p>
							We're in uncharted territory, hacking through the brush as we set
							out to explore whether—and how—AI can help us unearth deep,
							actionable customer insight.
						</p>
						<p>
							Can AI get us the depth of traditional qualitative research along
							with the scale of traditional quantitative research? And can those
							be integrated into our discovery process to gain early impressions
							and sentiment about a particular industry and our client?
						</p>
						<p>
							We wanted to start by seeing if we could better listen—and listen
							live.
						</p>
					</section>
				</div>
			</div>
			<div className="scrollingsections">
				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<img
									id="image1"
									className={`${isVideo1Visible ? "" : "hide"}`}
									src="../images/listening-live/synthesio_image_1_comp.jpg"
									alt="Synthesio pulls out sentiment analysis as well as more recent, higher-order “insights” in a side column to aid the analyst in pulling the most significant trends, getting a step closer to data interpretation."
								/>
								<img
									id="image2"
									className={`${isVideo2Visible ? "" : "hide"}`}
									src="../images/listening-live/synthesio_image_2_comp.jpg"
									alt="We can almost think of a system like this as a super-powerful search engine—it can tell us (a pretty good guess of) what the discussion about a given brand is like, and where it’s happening. The “where” is sometimes valuable in itself—interesting to know that a lot of organic discussion of insurance is seen as a “personal finance” issue—and can point us in a direction to learn more."
								/>
								<img
									id="image3"
									className={`${isVideo3Visible ? "" : "hide"}`}
									src="../images/listening-live/synthesio_image_3_comp.jpg"
									alt="Comparing AI-powered “word cloud” views allowed us to see any differences in how top insurers are discussed on social media, live."
								/>
								<img
									id="image4"
									className={`${isVideo4Visible ? "" : "hide"}`}
									src="../images/listening-live/article2_accent1_comp.jpg"
									alt="Digital Library Scene"
								/>
								<img
									id="image5"
									className={`${isVideo5Visible ? "" : "hide"}`}
									src="../images/listening-live/article2_accent2_comp.jpg"
									alt="Library Scene"
								/>
								<img
									id="image6"
									className={`${isVideo6Visible ? "" : "hide"}`}
									src="../images/listening-live/article2_accent3_comp.jpg"
									alt="Futuristic mall"
								/>
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<section className="video">
								<img
									src="../images/listening-live/synthesio_image_1_comp.jpg"
									alt="Synthesio pulls out sentiment analysis as well as more recent, higher-order “insights” in a side column to aid the analyst in pulling the most significant trends, getting a step closer to data interpretation."
								/>
							</section>
							<p>
								Social listening tools have been around for a while. Even
								methods from many years ago have used what we can loosely call
								“AI”—able to mine, analyze, and process data using machine
								learning and natural language processing, classifying clusters
								and themes, like positive and negative sentiment, to reveal them
								to the user. It’s common knowledge among those familiar with
								these tools that they’re generally time-consuming—and only as
								insightful as the analyst is diligent.
							</p>
							<p>
								<strong>
									In our quest to understand how AI can help us to better
									understand our fellow humans through consumer research, we
									were curious to explore this form of AI that we all know and
									some of us might love.
								</strong>{" "}
								To help glean a sense of what people out there are currently
								saying about a given brand and its competitors, we wanted to
								know how adding this tool to our repertoire could help us not
								only ingest a large quantity of social media posts, but also
								synthesize them into coherent insights useful for us and our
								client.
							</p>
							<p>
								We elected to test this hypothesis with a tool called{" "}
								<strong>Synthesio</strong>, which also includes AI-driven
								“Signals,” or a first pass at higher-order data interpretation,
								in addition to sentiment analysis. Synthesio combs various
								social media sites to understand how people are talking about a
								topic, trend, industry, or brand out there in the wild,{" "}
								<em>live</em>.
							</p>
							<p>
								Our goal: To figure out if Synthesio would be useful—as one
								input of many—during the discovery phase of a brand positioning
								project, alongside traditional (non-AI) stakeholder interviews,
								desktop cultural trends research, competitive audits, and Jobs
								to be Done interviews.
							</p>
							<p>
								What might we learn from how consumers were talking about the
								brand, its competitors, and the insurance industry in general?{" "}
							</p>
							<p>&nbsp;</p>
							<h3 className="section-subhead">
								First, we had to tell it what to listen for.
							</h3>
							<p>
								We wanted to know what people were saying naturally about an
								insurance company and its competitors. To start, we tuned the
								tool to look for the right stuff.
							</p>
							<p>
								The tool takes specific queries in a more traditional structured
								syntax. An example query—in this case for Progressive—looked
								like this:
							</p>

							<code>
								("Progressive") AND ((insurance) NOT (("Progressive") NEAR/2
								sponsor*))
							</code>
							<p>
								(A syntax note for the curious: The * asterisk lets the tool
								know we’re interested in all forms of that word. So sponsor*
								would yield sponsor, sponsored, sponsoring, etc.—yes, it’s still
								that manual, for now.)
							</p>
							<p>
								<em>The benefits of this input structure:</em>
							</p>
							<p>
								The structure allows us to get very <strong>specific</strong>{" "}
								and, importantly, <strong>raise our confidence</strong> that
								we’re looking at the company Progressive and not, for example,
								progressive politics. It also allowed us, in a very detailed
								way, to weed out the things we knew we didn’t want to see, like
								sponsored content.
							</p>
							<p>
								<em>The drawbacks:</em>
							</p>
							<p>
								That said, this style of intake is{" "}
								<strong>
									trickier than asking in <em>natural language</em>
								</strong>{" "}
								to "Look for people talking about State Farm" or "Look for
								people complaining about home insurance." There seems to be
								opportunity for an LLM layer to make the query process easier.
							</p>

							<p>&nbsp;</p>
							<h3 className="section-subhead">
								Then, we got data back—and had a lot to play with.
							</h3>
							<p>
								Though the gathering is done, it’s still a huge process to get
								to the useful insights out of the sea of data. As in most
								insight-hunting, some power comes through comparisons—between
								our target brand and its industry competitors (“how does what
								people are saying about Brand A compare to what they’re saying
								about Brand B?”), and between points of time (“how has
								discussion of Brand A changed in the last 3 months? in the last
								year?”).
							</p>
							<section className="video">
								<img
									src="../images/listening-live/synthesio_image_2_comp.jpg"
									alt="We can almost think of a system like this as a super-powerful search engine—it can tell us (a pretty good guess of) what the discussion about a given brand is like, and where it’s happening. The “where” is sometimes valuable in itself—interesting to know that a lot of organic discussion of insurance is seen as a “personal finance” issue—and can point us in a direction to learn more."
								/>
							</section>
							<p>
								So: our experience confirmed that this social listening tool
								still relies entirely on the diligence and insight of the
								analyst.{" "}
							</p>
							<p>
								When relying on the more automated “Signals” insights, Synthesio
								seems tuned to be most useful for longer-term brand tracking in
								the media, when you’re looking for the indicators up or down in
								sentiment following a recent campaign, for example—and
								comparatively less helpful for one-shot use as part of a
								discovery phase.
							</p>
							<p>
								That said, we still think it’s valuable, even if it’s used{" "}
								<strong>
									less for the at-scale data analysis and interpretation
								</strong>{" "}
								and <strong>more as an super-powerful search engine</strong> for
								specific anecdotal evidence of findings that resonated across
								our research streams.
							</p>

							<p>&nbsp;</p>
							<h4 className="section-subhead">
								A super-powerful search engine is still only as good as the
								questions it’s given.
							</h4>
							<p>
								With a system like this, being hypothesis-driven and asking
								pointed questions from the get go is a requirement—even when
								there’s ample time to explore. When the gem-hunting is a manual
								process, even with a more advanced system, you need it to dig in
								the right place. That starts with asking the right questions.
							</p>
							<p>
								We started by asking how people were talking about our client on
								the internet—and whether there were any notable differences in
								how people were talking about competitors.
							</p>
							<p>
								The “word cloud” feature came in handy when comparing
								conversations about two specific topics. The tool uses basic AI
								to group grammatical keywords and expressions, like adjectives,
								verbs, and noun chunks, from online conversations. It then
								layers sentiment analysis on top of that, displaying to the user
								how certain grammatical units are commonly contextualized in
								positive or negative sentiment, if any notable patterns exist.
							</p>
							<section className="video">
								<img
									src="../images/listening-live/synthesio_image_3_comp.jpg"
									alt="Comparing AI-powered “word cloud” views allowed us to see any differences in how top insurers are discussed on social media, live."
								/>
							</section>
							<p>
								We were able to compare our client’s word cloud with that of
								industry competitors. We could then click underneath each word
								and see all of the posts that used that term in conjunction with
								our client’s name—including the highest-engagement posts. And
								those posts were the gems.
							</p>

							<p>&nbsp;</p>
							<h4 className="section-subhead">
								The biggest drawback is what can be expected from social
								scraping tools to date: the at-scale data was pretty noisy.
							</h4>
							<section className="video">
								<img
									src="../images/listening-live/article2_accent1_comp.jpg"
									alt="Digital Library Scene"
								/>
							</section>
							<p>
								For example: we wanted to see the most frequently used terms in
								discussions surrounding our client’s well-known tagline. As it
								turns out, the pieces of media out there with the highest
								engagement (we’re talking tens of thousands of views here) was a
								sexually explicit ASMR account on YouTube that randomly cites
								the tagline in every video caption. That explained why certain
								unexpected terms were showing as highly associated with the
								tagline.
							</p>

							<p>
								Even then, these rather strange findings were still useful, as
								they supported one of our biggest hypothesis-led takeaways:{" "}
								<strong>our client itself is a cultural item</strong>. It just
								took more effort to see that through the noise.
							</p>
							<p>
								That gem—that our client itself is a cultural item—is a vastly
								valuable leverage point to consider while building a brand. And
								Synthesio provided a great window into the exemplary anecdotes
								that made it into our final insights deck.
							</p>
							<section className="video">
								<img
									src="../images/listening-live/article2_accent2_comp.jpg"
									alt="Library Scene"
								/>
							</section>
							<p>
								Because we used this tool for one-shot, discovery purposes,
								having access to source material behind the long-term trends was
								most helpful for us. By accessing that source material, we were
								able to see high-engagement posts that were explicitly referring
								to our client (thanks to our stringent criteria), but were doing
								so in unexpected ways. Even the word cloud example was most
								useful when we clicked behind the words to see what posts lay
								beneath them.
							</p>
							<p>
								In some of the most interesting cases, the quotes had little to
								do with insurance itself but something to do with protection,
								for example, even if it was ironic or in the context of a
								cartoon or crypto crashes—or just something that looked like the
								client’s mascot in a real-life context.
							</p>

							<p>&nbsp;</p>
							<h4 className="section-subhead">
								During our exploration, we could see the meanings that culture
								has associated with our client’s brand—gems aplenty, already in
								place. Finding those gems is what led to the most fertile
								ideation territory for our project.
							</h4>
							<section className="video">
								<img
									src="../images/listening-live/article2_accent3_comp.jpg"
									alt="Futuristic mall"
								/>
							</section>
							<p>
								From there, our job was to weave together the narrative we saw
								from the source material, along with our other inputs. AI
								brought the gems to the surface. We helped identify them, and
								then we forged and strung the necklace.
							</p>
							<p>
								The process wasn’t so different from our typical process at all.
								But it was faster with AI, which we essentially used as a
								super-powerful, super-specific search engine.
							</p>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								We also realized: there’s a tradeoff here.
							</h4>

							<p>
								The word cloud reminded us: Lippincott’s own tool Brand
								Aperture—along with a host of other insights—provides a much
								clearer view of the terms people associate with the brand. This
								improvement makes sense, as the data comes from a more
								controlled, intentionally-designed survey setting. In the
								context of the in-depth survey, we ask people to describe a
								brand, and can see the words they use most often. This method
								ensures a higher rate of confidence that the words appearing are
								actually associated with the brand—and not a
								grammatically-related-but-conceptually-unrelated phrase, or a
								random YouTube series. Not to mention the much more common
								downfall of sentiment analysis: sarcasm. On the other hand, the
								grammatically-linked-but-conceptually irrelevant phrase, the
								random YouTube series that happens to repeatedly mention
								something in its description, and sarcasm will (and do) easily
								fool a natural language processing model.{" "}
							</p>

							<p>
								That said: it’s also illuminating to see what’s out there, live.
							</p>

							<p>
								So the tradeoff, when it comes to listening: more precise and
								descriptive, or more in-the-wild and live?
							</p>

							<p>
								It’s a difference between what people might say and what they
								actually do. Both likely matter to different degrees, depending
								on the questions you want answered—and are maybe best viewed in
								concert with one another.
							</p>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								How might we get at even more useful insights about customers?
							</h4>

							<p>
								Stay tuned and find out whether we were able to use AI not only
								to gather data at scale, but also from individuals, one on one.{" "}
							</p>
							<p>
								<hr
									style={{
										border: "none",
										borderTop: "0.5px solid black",
										margin: "80px 0 40px",
									}}
								/>
								<a
									href="/from-listening-to-conversing-consumers-reddit-and-jtbd"
									style={{ color: "black", textDecoration: "underline" }}
								>
									<strong>
										Continue to the next article in this series &gt;
									</strong>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* END YOUR CUSTOM MARKUP HERE */}

			<Footer />
		</div>
	);
};

export default ListeningLive;

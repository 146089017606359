import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom"; 
import "./MainMenu.css";

const LippLogo = styled.img.attrs({
  src: process.env.PUBLIC_URL + "images/menu-images/lippincott-logo.png",
  className: "lippLogo",
})``;

const ArticleMenuLink = styled(Link).attrs((props) => ({
  className: "featuredImage",
  to: props.to,
}))`
  background-image: url(${(props) => props.bgimage});
`;

const MainMenu = () => {
  const location = useLocation();

  const toggleNav = () => {
    document.body.classList.toggle("openNav");
  };
  const closeNav = () => {
    document.body.classList.remove("openNav");
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const getCardDetails = () => {
    switch (location.pathname) {
      case "/":
        return {
          bgimage: "images/footer/articleCard.jpg",
          to: "/applying-the-power-of-ai-to-naming",
          title: "Applying the power of AI to naming",
        };
      case "/applying-the-power-of-ai-to-naming":
        return {
          bgimage: "images/footer/secondArticleCard.jpg",
          to: "/naming-the-next-generation",
          title: "Naming: The Next Generation",
        };
      case "/naming-the-next-generation":
        return {
          bgimage: "images/humans/article1_header_comp.jpg",
          to: "/can-ai-be-used-to-better-understand-humans",
          title: "Can AI be used to better understand humans?",
        };
      case "/can-ai-be-used-to-better-understand-humans":
        return {
          bgimage: "images/listening-live/article2_header_comp.jpg",
          to: "/listening-live",
          title: "Listening Live",
        };
      case "/listening-live":
        return {
          bgimage: "images/listening/article3_header_comp.jpg",
          to: "/from-listening-to-conversing-consumers-reddit-and-jtbd",
          title: "From listening to conversing: Consumers, Reddit, and JTBD",
        };
      case "/from-listening-to-conversing-consumers-reddit-and-jtbd":
        return {
          bgimage: "images/replicating/gundy1479_A_futuristic_library_with_many_books_and_crowded_peop_0767a76b-ceab-4013-a86e-d1b39b48721b%201.png",
          to: "/replicating-human-sentiment",
          title: "Replicating human sentiment",
        };
      case "/replicating-human-sentiment":
        return {
          bgimage: "images/lilah/gundy1479_A_futuristic_library_with_many_people_walking_around__dffc0ba9-530f-420d-9b8b-22466e9f083e%201.jpg",
          to: "/lilah",
          title: "Introducing Lilah",
        };
      case "/replicating-human-sentiment":
        return {
          bgimage: "images/p-block-1.jpg",
          to: "/",
          title: "Welcome to Play",
        };
      default:
        return {
          bgimage: "images/p-block-1.jpg",
          to: "/",
          title: "Welcome to Play",
        };
    }
  };
  const { to, title, bgimage } = getCardDetails();

  // Menu scroll animation on mobile ------------------------ //
  const [showMenu, setShowMenu] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (
        currentScrollY > 0 &&
        currentScrollY > lastScrollY &&
        window.innerWidth <= 480
      ) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
      setLastScrollY(currentScrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const menuStyle = showMenu ? {} : { transform: "translateY(-100%)" };

  return (
    <div>
      <section className="navContainer">
        <div className="navWrapper dFlex alignItemsCenter">
          <div className="navigationMenu">
            <ul>
              <li>
                <a
                  href="https://lippincott.com/about/"
                  target="coolTab"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="https://lippincott.com/work/"
                  target="coolTab"
                  rel="noopener noreferrer"
                >
                  Our Work
                </a>
              </li>
              <li>
                <a
                  href="https://lippincott.com/solutions/"
                  target="coolTab"
                  rel="noopener noreferrer"
                >
                  Solutions
                </a>
              </li>
              <li>
                <a
                  href="https://lippincott.com/ideas/ai-play/"
                  target="coolTab"
                  rel="noopener noreferrer"
                >
                  Ideas
                </a>
              </li>
            </ul>
            <div className="navigationMenu small">
              <ul>
                <li>
                  <a
                    href="https://lippincott.com/careers/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    href="https://lippincott.com/get-in-touch/"
                    target="coolTab"
                    rel="noopener noreferrer"
                  >
                    Contact & Locations
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="menuArticle">
            <ArticleMenuLink
              to={to}
              bgimage={bgimage}
              onClick={() => {
                closeNav();
                scrollToTop();
              }}
            />
            <Link
              className="articleLink"
              to={to}
              onClick={() => {
                closeNav();
                scrollToTop();
              }}
            >
              {title}
            </Link>
          </div>
        </div>
      </section>
      <nav className="mainNav" style={menuStyle}>
        <div className="navWrapper dFlex spaceBetween alignItemsCenter">
          <Link
            to="/?gohome=true"
            onClick={() => {
              closeNav();
              scrollToTop();
            }}
          >
            <LippLogo />
          </Link>
          <ul className="menuBtn" onClick={toggleNav}>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MainMenu;

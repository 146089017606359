import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./articles.css";
/**
 *
 */
const ReplicatingHumanSentiment = () => {
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	// Keep all left and right section refs in arrays
	const lSections = useRef([]);
	const rSections = useRef([]);

	// Methods to create refs when called upon DOM elements
	const pushLRef = (el) => lSections.current.push(el);
	const pushRRef = (el) => rSections.current.push(el);

	// Tracks video visibility. Set upon scroll position
	const [isVideo1Visible, setShowVideo1] = useState(true);
	const [isVideo2Visible, setShowVideo2] = useState(false);
	const [isVideo3Visible, setShowVideo3] = useState(false);
	const [isVideo4Visible, setShowVideo4] = useState(false);

	useEffect(() => {
		let tl = gsap.timeline();
		let mm = gsap.matchMedia();
		const sectionsLength = lSections.current.length;

		for (let i = 0; i < sectionsLength; i++) {
			mm.add("(min-width: 992px)", () => {
				let startVal = "clamp(top top)";
				let endVal = "clamp(bottom center)";
				if (i < 2) {
					startVal = "clamp(top 64px)";
					endVal = "clamp(bottom bottom-400px)";
				}
				tl.to(rSections.current[i], {
					scrollTrigger: {
						pin: lSections.current[i],
						trigger: rSections.current[i],
						start: startVal,
						end: endVal,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						markers: false,
						// Tracks scroll progression (progress is a value of 0-1)
						// i value represents which left section is currently in view
						onUpdate: ({ progress }) => {
							// console.log("i: ", i, " || progress: ", progress.toFixed(2));
							if (i === 0) {
								if (progress >= 0.63) {
									//show video 4
									// console.log("show video 4");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(true);
								} else if (progress >= 0.46) {
									//show video 3
									// console.log("show video 3");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(true);
									setShowVideo4(false);
								} else if (progress >= 0.24) {
									//show video 2
									// console.log("show video 2");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(true);
									setShowVideo3(false);
									setShowVideo4(false);
								} else {
									//show video 1
									// console.log("show video ");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(true);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(false);
								}
							}
						},
					},
				});
			});
		}

		ScrollTrigger.sort();
	}, [lSections]);

	return (
		<div className="main-wrapper">
			<MainMenu />

			{/* BEGIN YOUR CUSTOM MARKUP HERE */}

			<div className="hero light-mode">
				<div className="left replicating curtain">
					<h1>
						<div className="eyebrow">Experiment 03:</div>
						Replicating human sentiment
					</h1>
					<div className="info">
						<div className="breadcrumb">Consumer Research + ai</div>
						<div className="tagging">
							<ul>
								<li>
									<a>#Consumerresearch</a>
								</li>
								<li>
									<a>#Insights</a>
								</li>
								<li>
									<a>#Brand</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="right">
					<p className="title">
						Play<span>Another Lippincott AI Exploration</span>
					</p>
					<section>
						<p className="initial-cap">
							Everyone is worried we’ll all be replaced by AI versions of
							ourselves.
						</p>

						<p>
							Quite dystopian to be sure, but how much merit is there to that
							thinking? While our experiments have shown that AI can’t yet take
							the place of human researchers, we wanted to know if it could
							replace the research participants.{" "}
						</p>

						<p>
							Could AI really understand, capture, and replicate human
							sentiment?
						</p>

						<p>Let’s find out.</p>
					</section>
				</div>
			</div>
			<div className="scrollingsections">
				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<img
									id="image1"
									className={`${isVideo1Visible ? "" : "hide"}`}
									src="../images/replicating/gundy1479_httpss.mj.runH7X9FDWr8vM_httpss.mj 1.jpg"
									alt="Futuristic Mall"
								/>
								<video
									id="video1"
									controls
									className={`${isVideo2Visible ? "" : "hide"}`}
									poster="../images/naming/video1.png"
									src="../images/replicating/Visceral 2A.mp4"
								></video>

								<video
									id="video2"
									controls
									className={`${isVideo3Visible ? "" : "hide"}`}
									poster="../images/naming/video2.png"
									src="../images/replicating/Visceral 2B.mp4"
								></video>

								<video
									id="video3"
									controls
									className={`${isVideo4Visible ? "" : "hide"}`}
									poster="../images/naming/video3.png"
									src="../images/replicating/Visceral 2C.mp4"
								></video>
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<p>
								As you saw in our last experiment, we partnered with a startup
								in the summer of 2023 to see if AI could act as a synthetic
								interviewer and replicate the deep conversation needed for Jobs
								to Be Done (JTBD) research.
							</p>
							<section className="video">
								<img
									src="../images/replicating/gundy1479_httpss.mj.runH7X9FDWr8vM_httpss.mj 1.jpg"
									alt="Futuristic Mall"
								/>
							</section>
							<p>
								While this investigative interviewing is decidedly an effort
								that still requires the human touch, we found ourselves
								asking&hellip;
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead">
								What if we flipped the script and attempted to use AI as a
								virtual interviewee, rather than a virtual interviewer?
							</p>

							<p>
								We see significant potential for AI to step in and become a
								synthetic, durable representation of the target consumer. And
								it’s not that far off&hellip;
							</p>

							<p>
								For those of us who have been experimenting with tools like
								ChatGPT in the past year, we have seen the power of leveraging
								AI in the same way we would a human expert. Ask it a question,
								get an answer. Simple as that.
							</p>

							<p>
								We wanted to see if an LLM like ChatGPT could become a very
								specific expert: an expert representation of a consumer.
							</p>

							<p>
								Our goal in this experiment was to leverage AI to create a
								digital version of a living and breathing customer that we could
								ask questions and get responses—not direct quotes from a single
								respondent, but rather an interpretation of a cluster of
								respondents.
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead-smaller centered">
								AI Interviewee:
								<br />
								<em>
									A generative AI agent based in an LLM that can ingest datasets
									(like interview transcripts) and then represent a persona to
									be queried, generating responses to questions based on the
									information it has received about the target population,
									speculating based on set parameters and its underlying models.
								</em>
							</p>
							<p>&nbsp;</p>
							<p>
								Using the AI Interviewee tool, we can ask questions to an
								enduring digital representation of a target population—questions
								about beliefs and motivations, but also about opinions on a new
								brand positioning, name, or product. Imagine it in the hands of
								client teams across the organization, able to ask a smart
								representation of their target customer any number of questions.
								It could bring research to life in a totally new way.
							</p>

							<p>
								And down the line, it could be paired with the Interviewer agent
								discussed in our last article—or Google analytics, Salesforce,
								or other live client engagement data—so that it shifts right
								alongside the corresponding consumer population, live.
							</p>

							<p>
								It’s been exciting to experiment with the tool in its earliest
								days and we see major potential here.it shifts right alongside
								the corresponding consumer population, live.
							</p>
							<video
								controls
								poster="../images/naming/video1.png"
								src="../images/replicating/Visceral 2A.mp4"
							></video>
							<p>&nbsp;</p>
							<p className="section-subhead">
								We started by loading transcripts from our live qualitative
								interviews from our insurance client into the AI interviewee.{" "}
							</p>

							<p>
								It did a really nice job ingesting a large quantity of data to
								try to understand the people that we spoke with in our live
								interviews. We were able to have the tool produce pretty
								well-thought-out job articulations, or statements that describe
								consumer challenges and the progress they are looking to make in
								their life (albeit lacking some of the emotive story telling
								that we typically employ), along with insights and needs.
							</p>
							<video
								controls
								poster="../images/naming/video2.png"
								src="../images/replicating/Visceral 2B.mp4"
							></video>
							<p>&nbsp;</p>
							<p className="section-subhead">
								But when it came down to truly being a synthetic user—one that
								can speculate and respond like one of our personas—that was a
								bit of a stretch.
							</p>

							<p>
								Our results were pretty promising, particularly the tool’s
								ability to respond to our questions about the people we trained
								it on. We could see where it could be useful if it were able to
								mine large sets of publicly available data rather than our
								interview transcripts.
							</p>

							<p>
								We learned to watch for quality short-term memory and a
								“willingness” to speculate. There’s a delicate balance between
								allowing for misinformation and allowing for speculation.
							</p>

							<p>
								But instead of replaying information directly from individual
								interviews and sticking strictly to what was explicitly
								discussed, how cool and useful would it be to have a bot
								brainstorming alongside us, figuring out what a target consumer
								persona might think about this product idea, or that positioning
								statement
							</p>
							<video
								controls
								poster="../images/naming/video3.png"
								src="../images/replicating/Visceral 2C.mp4"
							></video>
							<p>&nbsp;</p>
							<p className="section-subhead">
								This tool is helping to blaze a trail in what we think is a huge
								area of potential: bringing consumer research to life and
								helping cross-organizational teams care more about it and learn
								from it.
							</p>

							<p>
								One of the things we’re most excited about when it comes to the
								AI Interviewee: What if we could have the tool ingest customer
								segment or cluster data and take on that persona to enable an
								ongoing conversation full of feedback, insight, and new needs
								and challenges. This isn’t possible in the tool’s current form,
								but it’s in the works—and we can’t wait to play around with it
								when it’s ready.{" "}
							</p>

							<p>
								As our AI relationship marches forward, we’re hoping for ways to
								deepen engagement in the AI Interviewer and create an
								amalgamation of a customer in the AI Interviewee, then connect
								the two platforms—so the real people from the Interviewer then
								become the source data for the Interviewee to learn from. This
								would be a huge step in bringing research to life in a powerful
								way.
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead">
								It’s not surprising that, for now, the process of synthesizing
								very human experiences, emotions, and mindsets is best left to a
								human.
							</p>

							<p>
								We recognize that bringing human experiences to life through
								compelling stories is something humans may always do best. It is
								something we’ll explore more in our next experiment where rather
								than giving raw data to an AI tool to let it create the persona,
								we explore how training an LLM on a rich persona that we humans
								created can increase customer-centricity throughout an
								organization.
							</p>
							<p>
								<hr
									style={{
										border: "none",
										borderTop: "0.5px solid black",
										margin: "80px 0 40px",
									}}
								/>
								<a
									href="/lilah"
									style={{ color: "black", textDecoration: "underline" }}
								>
									<strong>
										Continue to the next article in this series &gt;
									</strong>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* END YOUR CUSTOM MARKUP HERE */}

			<Footer />
		</div>
	);
};

export default ReplicatingHumanSentiment;

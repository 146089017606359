import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./articles.css";
/**
 *
 */
const Lilah = () => {
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	// Keep all left and right section refs in arrays
	const lSections = useRef([]);
	const rSections = useRef([]);

	// Methods to create refs when called upon DOM elements
	const pushLRef = (el) => lSections.current.push(el);
	const pushRRef = (el) => rSections.current.push(el);

	// Tracks video visibility. Set upon scroll position
	const [isVideo1Visible, setShowVideo1] = useState(true);
	const [isVideo2Visible, setShowVideo2] = useState(true);
	const [isVideo3Visible, setShowVideo3] = useState(false);
	const [isVideo4Visible, setShowVideo4] = useState(false);
	const [isVideo5Visible, setShowVideo5] = useState(false);

	useEffect(() => {
		let tl = gsap.timeline();
		let mm = gsap.matchMedia();
		const sectionsLength = lSections.current.length;

		for (let i = 0; i < sectionsLength; i++) {
			mm.add("(min-width: 992px)", () => {
				let startVal = "clamp(top top)";
				let endVal = "clamp(bottom center)";
				if (i < 2) {
					startVal = "clamp(top 64px)";
					endVal = "clamp(bottom bottom-400px)";
				}
				tl.to(rSections.current[i], {
					scrollTrigger: {
						pin: lSections.current[i],
						trigger: rSections.current[i],
						start: startVal,
						end: endVal,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						markers: false,
						// Tracks scroll progression (progress is a value of 0-1)
						// i value represents which left section is currently in view
						onUpdate: ({ progress }) => {
							console.log("progress: ", progress.toFixed(2));
							if (i === 0) {
								if (progress >= 0.6) {
									//show video 6
									// console.log("show video 4");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(true);
								} else if (progress >= 0.45) {
									//show video 4, 5
									// console.log("show video 3");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(false);
									setShowVideo4(true);
									setShowVideo5(false);
								} else if (progress >= 0.32) {
									//show video 3
									// console.log("show video 2");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(false);
									setShowVideo2(false);
									setShowVideo3(true);
									setShowVideo4(false);
									setShowVideo5(false);
								} else {
									//show video 1, 2
									// console.log("show video 1");
									// console.log("progress: ", progress.toFixed(2));
									setShowVideo1(true);
									setShowVideo2(true);
									setShowVideo3(false);
									setShowVideo4(false);
									setShowVideo5(false);
								}
							}
						},
					},
				});
			});
		}

		ScrollTrigger.sort();
	}, [lSections]);

	return (
		<div className="main-wrapper">
			<MainMenu />

			{/* BEGIN YOUR CUSTOM MARKUP HERE */}

			<div className="hero light-mode">
				<div className="left lilah curtain">
					<h1>
						<div className="eyebrow">Experiment 04:</div>
						Introducing Lilah
					</h1>
					<div className="info">
						<div className="breadcrumb">Consumer Research + ai</div>
						<div className="tagging">
							<ul>
								<li>#Consumerresearch</li>
								<li>#Insights</li>
								<li>#Brand</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="right">
					<p className="title">
						Play<span>Another Lippincott AI Exploration</span>
					</p>
					<section>
						<p className="initial-cap">
							Meet Lilah. She’s a 45-year-old marketing exec living in Oakland,
							California, with her husband and children.
						</p>

						<p>
							“We’ve got a pretty good life - successful careers, a nice income,
							and a comfortable lifestyle,” she says. “But I’d say I’m still
							pretty ambitious and always striving for more.”
						</p>
						<p>
							Lilah’s got personality and opinions aplenty, and she’s not afraid
							to speak frankly. She knows what she wants, and she’s determined
							to go after it.
						</p>
						<p>
							Spoiler alert: She also doesn’t exist. Lilah is a bot built to
							personify a specific, well-defined audience segment. But even
							though we brought Lilah to life, there’s a lot she can teach us.
							And she can give brand teams a better way to keep the customer at
							the forefront of every conversation.
						</p>
						<p>Let’s see what Lilah is all about.</p>
					</section>
				</div>
			</div>
			<div className="scrollingsections">
				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<img
									id="image1"
									className={`${isVideo1Visible ? "" : "hide"}`}
									src="../images/lilah/persona_shot1.jpg"
									alt="Lilah Persona"
								/>
								<img
									id="image2"
									className={`${isVideo2Visible ? "" : "hide"}`}
									src="../images/lilah/persona_shot2.jpg"
									alt="Meet Lilah"
								/>
								<video
									id="video1"
									controls
									className={`${isVideo3Visible ? "" : "hide"}`}
									poster="../images/naming/video1.png"
									src="../images/lilah/Prompt_redacted_v2.mp4"
								></video>

								<video
									id="video2"
									controls
									className={`${isVideo4Visible ? "" : "hide"}`}
									poster="../images/naming/video2.png"
									src="../images/lilah/Lilah_combo.mp4"
								></video>

								<video
									id="video3"
									controls
									className={`${isVideo5Visible ? "" : "hide"}`}
									poster="../images/naming/video3.png"
									src="../images/lilah/New app.mp4"
								></video>
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<p>
								As you saw in our last experiment, we partnered with a startup
								in the summer of 2023 to see if AI could act as a synthetic
								interviewer and replicate the deep conversation needed for Jobs
								to Be Done (JTBD) research.
							</p>

							<p>
								The foundation of any meaningful brand is a customer-obsessed
								team—a team that intimately understands the audiences it serves
								and passionately builds a brand focused on addressing those
								needs. All temptations to tweak or tinker without reason are
								quickly squashed, with a ruthless discipline of asking, “What
								does our customer need us to do? Cut the rest.”
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead">
								The lifeblood of these customer-obsessed teams is easy access to
								candid perspectives from the representative customers they aim
								to serve.
							</p>

							<p>
								This need for candid perspectives is the foundation of our
								consumer insights experimentation.
							</p>

							<p>
								In our last 3 experiments, we looked at ways in which we could
								potentially use AI to get quicker, cheaper, and more relevant
								forms of customer insights that can reveal opportunities to
								create greater impact for our clients, and design more
								meaningful interactions for their customers.
							</p>
							<p>
								While we struggled to get the depth of insight we really wanted
								in those experiments, this work sparked a new idea and some new
								questions:
							</p>

							<ul className="list-style">
								<li>
									Can we use our traditional insights processes and then use AI
									to create a bot that personifies a specific and well-defined
									audience segment?
								</li>
								<li>
									If so, could it provide us and our clients meaningful and
									relevant insights that live beyond a one-off interview?
								</li>
								<li>
									And can we train it to use details about this audience to
									provide us realistic feedback on how the humans in the segment
									might respond to a new idea, message, or proposition?
								</li>
							</ul>

							<p>
								We started with something familiar to many brand teams across
								industries—a customer segment. Our segment was rigorously
								developed through a quantitative survey with more than 10,000
								respondents. The survey captured their defining attitudes,
								needs, preferences, behaviors, and more.
							</p>
							<section className="video">
								<img
									src="../images/lilah/persona_shot1.jpg"
									alt="Lilah Persona"
								/>
								<img src="../images/lilah/persona_shot2.jpg" alt="Meet Lilah" />
							</section>
							<p>
								That segment was then transformed by our team into a persona—a
								vibrant amalgamation of these defining characteristics in a
								single hypothesized individual with a unique story, point of
								view, and personality. We call her Lilah.
							</p>

							<p>
								Before this experiment, Lilah lived in 2D. Her attitudes and
								characteristics were captured across spreadsheets, her history,
								interests, and hobbies detailed thoughtfully in a presentation.
							</p>

							<p>
								But while Lilah is not a real person—or even based on one—she’s
								so much more than these handful of documents. Think of her like
								a character in a long-running TV show; she has a backstory, she
								has a future, she presents herself slightly differently at work
								than around friends, she has little quirks that make the viewer
								laugh or roll their eyes, and while the viewer can presume what
								will happen to her next season, we never know for sure.
							</p>

							<p>
								While this concept is easy to grasp conceptually, in our years
								of conducting segmentation studies and developing personas for
								clients, we’ve come to identify a common challenge…
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead">
								There are select individuals across the organization that are
								able to see Lilah as more than words on a PowerPoint slide or
								lines in an Excel sheet… and then there’s everyone else.
							</p>

							<p>
								To make the persona more accessible, we wanted to see if Lilah
								could make the leap from fictional, 2D character to an (almost)
								living, breathing AI bot that was able to interact and engage
								with us in real-time.
							</p>

							<p>
								We set out to leverage all that we’d built around Lilah to train
								a large language model (LLM) to embody her—replicating{" "}
							</p>

							<p>
								Selecting the LLM for our experiment was the easy part. We
								tested a number, but selected Claude.ai for its large context
								window and frictionless acceptance of CSV inputs. Claude.ai also
								exhibited a decent memory, so keeping ongoing conversations
								going with Lilah would be a breeze.
							</p>

							<p>
								From there, the prompt engineering began. Our prompt initially
								consisted of three parts: the hard data (both quantitative and
								qualitative), the persona’s story, and the task. But, as we
								worked through, we discovered that Lilah was lacking the
								personality we all knew she had—plus, at times, she struggled to
								exhibit the nuance of the data we had provided.{" "}
							</p>
							<section className="video">
								<video
									controls
									poster="../images/naming/video1.png"
									src="../images/lilah/Prompt_redacted_v2.mp4"
								></video>
							</section>
							<p>
								This was our “aha”: when people talking about “training an AI,”
								they mean this literally.
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead">
								A prompt made up of just data will miss the mark; it’s the
								flourishes that help to define an assumed personality—those same
								quirks and nuanced ways characters on TV interact with their
								worlds. We needed to provide Lilah with that same direction.
							</p>

							<p>
								So, we added discrete direction to the prompt and educated the
								bot on the data we were providing to ensure it could not only
								reference and recite the data, but use the information we
								provided correctly to embody Lilah. We also contextualized who
								Lilah is (what she sounds like, how she thinks, how she
								interacts with others, what brands she uses or might avoid)
								through a screen-play-like story that explores Lilah’s daily
								experiences.
							</p>

							<p>
								These are the elements that really transformed Lilah from 2D to
								3D. While other AI bots may resemble a character, we created a
								version that’s more useful for brand and experience by infusing
								Lilah with personality and backstory.
							</p>
							<section className="video">
								<video
									controls
									poster="../images/naming/video2.png"
									src="../images/lilah/Lilah_combo.mp4"
								></video>
							</section>
							<p>
								The Lilah Bot excelled at impersonating the persona, effectively
								responding to prompts based on what the LLM had been taught and
								in the voice of the persona. It was also able to retrieve
								segment-specific data that it had been trained on, reciting
								accurate stats from conversational prompts to make accessing
								this information much easier than navigating a complex
								spreadsheet.
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead">
								What really excited us was the Lilah Bot’s ability to humanize
								the responses to feedback we requested.
							</p>

							<p>
								The Lilah Bot described a typical ‘day in their life’, helping
								the real human at the other end of the keyboard build empathy
								and understanding in tensions and mindsets that inspire new
								forms of engagement.
							</p>

							<p>
								We even successfully got it to dive into realities beyond its
								programming: it chose between Uber and Lyft, for instance, and
								told us how this persona might feel about scalloped-edge toilet
								paper.
							</p>

							<p>
								Lilah was also able to interact with another AI persona we
								created, which provided some interesting insight. It was like
								having two different customers in a focus group talking and
								giving each other advice.
							</p>

							<p>
								Was the Lilah Bot perfect? No. There were certainly moments of
								unproductive hallucination and challenges delineating between
								data points that may reflect different but related ideas. But
								our refinement of the prompt and curation of the data had
								immediate benefits.
							</p>

							<p>
								So, what does the Lilah Bot mean for those seeking to build
								meaningful brands? Our problem statement at the top of this
								article was that traditional forms of research are static,
								complex, and inefficient. Well, we still need all of those
								complex inputs to train an AI to truly represent a persona—but
								all of that happens once. The inefficiency becomes efficiency
								when that one-time research turns into lifelong insight.
							</p>
							<p>&nbsp;</p>
							<p className="section-subhead">
								The bot’s ability to project Lilah’s attitudes to new concepts
								presents promising opportunities to explore and experiment in a
								more efficient, albeit speculative way than spinning up
								additional rounds of consumer research.
							</p>

							<p>
								For example: We asked Lilah, if we were creating a new app for
								her, what would be the most important messages to make her want
								to try it? What would it have to do for her life? What would she
								want the experience to be like?
							</p>
							<section className="video">
								<video
									controls
									poster="../images/naming/video3.png"
									src="../images/lilah/New app.mp4"
								></video>
							</section>
							<p>
								Lilah represents tremendous potential for putting more candid,
								relevant, and meaningful customer insight in more people’s
								hands. We see this as a promising step to help our clients move
								from being customer-obsessed teams to customer-obsessed
								organizations.
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* END YOUR CUSTOM MARKUP HERE */}

			<Footer />
		</div>
	);
};

export default Lilah;

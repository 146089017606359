import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import "./articles.css";

const AIHumans = () => {
	gsap.registerPlugin(useGSAP);
	gsap.registerPlugin(ScrollTrigger);

	// Keep all left and right section refs in arrays
	const lSections = useRef([]);
	const rSections = useRef([]);

	// Methods to create refs when called upon DOM elements
	const pushLRef = (el) => lSections.current.push(el);
	const pushRRef = (el) => rSections.current.push(el);

	// Tracks video visibility. Set upon scroll position
	const [isVideo1Visible, setShowVideo1] = useState(true);

	// Tracks video visibility. Set upon scroll position
	useEffect(() => {
		let tl = gsap.timeline();
		let mm = gsap.matchMedia();
		const sectionsLength = lSections.current.length;

		for (let i = 0; i < sectionsLength; i++) {
			mm.add("(min-width: 992px)", () => {
				let startVal = "clamp(top top)";
				let endVal = "clamp(bottom center)";
				if (i < 2) {
					startVal = "clamp(top 64px)";
					endVal = "clamp(bottom bottom-400px)";
				}
				tl.to(rSections.current[i], {
					scrollTrigger: {
						pin: lSections.current[i],
						trigger: rSections.current[i],
						start: startVal,
						end: endVal,
						pinSpacing: false,
						scrub: true,
						invalidateOnRefresh: true,
						markers: false,
						// Tracks scroll progression (progress is a value of 0-1)
						// i value represents which left section is currently in view
						onUpdate: ({ progress }) => {
							// console.log("i: ", i, " || progress: ", progress.toFixed(2));
							if (i === 0) {
								setShowVideo1(true);
							}
						},
					},
				});
			});
		}

		ScrollTrigger.sort();
	}, [lSections]);

	return (
		<div className="main-wrapper">
			<MainMenu />

			{/* BEGIN YOUR CUSTOM MARKUP HERE */}

			<div className="hero--full humans light-mode curtain">
				<div className="left">
					<p className="title">
						Play<span>Another Lippincott AI Exploration</span>
					</p>
					<h1>Can AI be used to better understand humans?</h1>
					<div className="info">
						<div className="breadcrumb">consumer research + ai</div>
						<div className="tagging">
							<ul>
								<li>#Consumerresearch</li>
								<li>#Insights</li>
								<li>#Brand</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="scrollingsections">
				{/* <!-- section one  --> */}
				<div className="scroll-element">
					<div className="left" ref={pushLRef}>
						<div className="mediawrapper">
							<section className="video">
								<img
									id="image1"
									className={`${isVideo1Visible ? "" : "hide"}`}
									src="../images/humans/article1_accent_comp.jpg"
									alt="AI Librarian helping out"
								/>
							</section>
						</div>
					</div>
					<div className="right" ref={pushRRef}>
						<div>
							<section className="video">
								<img
									src="../images/humans/article1_accent_comp.jpg"
									alt="AI Librarian helping out"
								/>
							</section>
							<p class="drop-cap">
								As you might have read, we see a future where we work alongside
								and collaboratively with AI to create great brands. To do this,
								we must constantly learn new ways to harness human insights and
								understand those we’re building brands for. But brands are built
								collaboratively—so a burning question on our minds is...
							</p>
							<p>&nbsp;</p>
							<h2 className="section-subhead">
								Could we work in tandem with AI to make brands <em>more</em>{" "}
								human?
							</h2>
							<p>
								Let’s think about consumer research and how it enables brands to
								deliver more human products, services, experiences, and
								messages. Research helps us better understand target audiences
								so we can see the world through their eyes, define their
								individual nuances, and determine what matters most to them.
								These insights empower brands to recognize and capitalize on new
								opportunities—creating offerings that are tailored to their
								customers’ specific needs and preferences.
							</p>
							<p>
								While invaluable in helping brands stay a step ahead,
								traditional consumer research presents a set of challenges like:
							</p>
							<ul className="list-style">
								<li>
									The inherent tension and tradeoff between deep individual
									insights and broadly representative data at scale
								</li>
								<li>
									The timeline to conduct traditional research and the fact that
									it only presents us with a snapshot of customers in a specific
									moment in time
								</li>
								<li>
									The ability to synthesize raw research inputs into game
									changing brand insights
								</li>
								<li>
									And the need to engrain these insights throughout an entire
									organization to help every facet of the brand and business be
									customer-centric
								</li>
							</ul>
							<p>
								While these are challenges that we deal with every day, we’re
								interested in seeing how AI can help.
							</p>
							<p>
								Can we use AI to conduct interviews and gather detailed
								individual data at scale? Can we slash research timeframes
								without sacrificing quality? Can we develop mechanisms for
								ongoing, always-on insights generation? Can AI help surface the
								insights needle from the haystack of data? Can AI be used to
								replicate human sentiment? What would that look like? Do these
								tools fit into our process? Do they fit together? And what does
								the data we get from these tools look like? Questions and more
								questions.
							</p>
							<p>&nbsp;</p>
							<h4 className="section-subhead">
								So how do we find the answers?
							</h4>
							<h4 className="section-subhead">
								We <u>PLAY</u>.
							</h4>

							<p>
								Join us as we try out different tools and learn along the way.
								Come learn along the way with us, too.
							</p>
							<p>
								<hr
									style={{
										border: "none",
										borderTop: "0.5px solid black",
										margin: "80px 0 40px",
									}}
								/>
								<a
									href="/listening-live"
									style={{ color: "black", textDecoration: "underline" }}
								>
									<strong>
										Continue to the next article in this series &gt;
									</strong>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			{/* END YOUR CUSTOM MARKUP HERE */}

			<Footer />
		</div>
	);
};

export default AIHumans;
